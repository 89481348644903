
import { useEffect } from "react";
import lightlogo from "../assets/images/logo/light-logo.png";    
import { Link } from "react-router-dom";






function Service() {

 
const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
     // externalScript.onerror = loadError;
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = '../assets/js/main.js';
};
LoadExternalScript();


// useEffect(()=>{require('../assets/js/main.js')},[])

  return (

    
        <>
      
              <div id="page" className="section">
        
        <div className="header-section header-transparent sticky-header section">
        <div className="header-inner">
            <div className="container position-relative">
                <div className="row justify-content-between align-items-center">

                   
                   <div className="col-xl-2 col-auto order-0">
                        <div className="header-logo">
                                     
                                            < Link  to="/"  onClick={() => {window.location.href="/"}}>
                                 {/* <img src={darklogo} alt="dark-logo's logo"/> */}
                                    {/* <img src={iicon} alt="Anon's logo" width="120" height="36" /> */}
                                     <img src={lightlogo} alt="dark-logo's logo"/>
                                   </Link>
                                </div>
                            </div>
                        <div
                            className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                            <div className="menu-column-area d-none d-xl-block position-static">
                                <nav className="site-main-menu">
                                     <ul>
                                              <li>
                                                  < Link  to="/"  onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                        </li>
                                              <li>
                                                    < Link  to="/Service" className="active" onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                        </li>
                                    </ul>
                                   
                                </nav>
                            </div>
                           
                            <div className="header-search-area ml-xl-7 ml-0">

                               
                               <div className="header-search">
                                    <a href="javascript:void(0)" className="header-search-toggle"></a>
                                </div>
                                
                            </div>
                           
                                  <div className="header-mobile-menu-toggle
                            onClick={toggleMobileMenu}
                            d-xl-none ml-sm-2">
                                <button className="toggle">
                                    <i className="icon-top"></i>
                                    <i className="icon-middle"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                           
                        </div>
                        

                       
                     
                    </div>
                </div>
            </div>
        </div>

       
         <div className="page-title-section section section-padding-top" data-overlay="0.7" data-bg-image="assets/images/bg/breadcrumb-bg-two.jpg">
            <div className="page-title">
            
            </div>
            
        </div>
       
       <div className="section section-padding">
            <div className="container">
               
                
                <div className="row">
                    <div className="col-lg-12">
                        <div className="custom-column-thumbnail mt-lg-14 mt-10" data-aos="fade-up">
                                  <img className="w-100" src="assets/images/work-details/hero-7.jpg" alt="bvc"></img>
                                     {/* <img className="w-100" src="assets/images/work-details/work-details-1.png" alt="Agency"></img> */}
                        </div>
                    </div>
                </div>

                
                <div className="row mt-lg-20 mt-12">
                    <div className="col-lg-2 col-md-12 col-12">
                        <div className="digital-marketing" data-aos="fade-up">
                            <h3 className="heading heading-h3">Chartered Accountants .</h3>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-12 col-12 offset-lg-1">
                        <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                            <div className="inner">
                                      <p>

                                          Tax Administration means the administration, management, conduct, direction and supervision of the execution and application of the tax laws of a country, and the development and formulation of tax policy relating to existing or proposed tax laws of this country, and includes assessment, collection, enforcement, litigation, publication and statistical gathering functions under such laws.
Tax administration involves the registration, assessment, returns, collection, compliance monitoring, compliance enforcement, sanction, taxpayer’s education and awareness and any other activity that can improve the efficiency and effectiveness of taxation
                                          .</p>
                            </div>
                        </div>
                    </div>
                </div>
               
                


            </div>
        </div>
 <div className="footer-section section" data-bg-color="#030e22">
            <div className="container">
        
                
                <div className="row mb-lg-14 mb-md-10 mb-6">
        
                    
                          
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                        
                            <div className="footer-widget-content">
                                <div className="content">
        
        
                                    <p><a href="#">+2348117390319</a></p>
                                    <p><a href="#">info@bvcpartners.com.ng</a> </p>
                                </div>
                                <div className="footer-social-inline">
                                    
 <a href="https://www.facebook.com/bvcpartners.com.ng?mibextid=ZbWKwL/"><i className="fab fa-facebook-square"></i></a>
                                         <a href=" https://www.instagram.com/bvcpartners?utm_source=qr&igsh=YnU0aHJveWxnbXhr/"><i className="fab fa-instagram"></i></a>

                                   <a href="https://www.linkedin.com/company/bola-vaughan-co-chartered-accountants/"><i className="fab fa-linkedin-in"></i></a>
                                    
        
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Services</h4>
                            <div className="footer-widget-content">
                                <ul>
                                          <li>
                                    < Link  to="/Sdetails_2"onClick={() => {window.location.href="/Sdetails_2"}}>  <span className="menu-text">Tax Mgt Services</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Audit Services</span></Link>
                                          </li>
                                               <li>
                                    < Link  to="/Sdetails_6"onClick={() => {window.location.href="/Sdetails_6"}}>  <span className="menu-text">Human Capital Mgt & Development</span></Link>         
                                          </li>
                                          <li>
                                    < Link  to="/Sdetails_5"onClick={() => {window.location.href="/Sdetails_5"}}>  <span className="menu-text">Cyber Security & IT Services</span></Link>         
                                   </li>
                                          
                                   
                                           <li>
                                    < Link  to="/Sdetails_3"onClick={() => {window.location.href="/Sdetails_3"}}>  <span className="menu-text">BPM</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Investment Advisory Services</span></Link>
                                          </li>
                                            <li>
                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules ERP</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Subscription_plan"  onClick={() => {window.location.href="/Subscription_plan"}}> <span className="menu-text">Subscription ERP </span></Link>
                                          </li>
                                         
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">About</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Features</a></li>
                                    <li><a href="#">Our Partners</a></li>
                                    <li><a href="#">Affiliate Program</a></li>
                                          <li><a href="#">Pricing</a></li>
                                          
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Contact</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Support Ticket</a></li>
                                    <li><a href="#">Help Desk</a></li>
                                    <li><a href="#">Live Chat</a></li>
                                    <li><a href="#">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
        
                </div>
                
                <div className="row">
                    <div className="col">
                        <p className="copyright">Copyright &#169; 2024 BVCPartners LP. All Rights Reserved  </p>
                    </div>
                </div>
               
        
            </div>
        </div>


  
        <a href="#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up"></i>
            <i className="arrow-bottom fal fa-long-arrow-up"></i>
        </a>
  
    </div>

    <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <a href="index.html"><img src="assets/images/logo/light-logo.png" alt=""></img></a>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle">
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <nav className="site-mobile-menu">
                <ul>
                                              <li>
                                                  < Link  to="/"  onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                        </li>
                                              <li>
                                                    < Link  to="/Service" className="active" onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                        </li>
                                    </ul>
                </nav>
            </div>
              </div>
              
     
    </div>
      
      </> 

    
  );
}


export default Service;