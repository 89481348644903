
import { useEffect } from "react";
import lightlogo from "../assets/images/logo/light-logo.png";   
import { Link } from "react-router-dom";



function Contact() {

 
  const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
     // externalScript.onerror = loadError;
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = '../assets/js/main.js';
};
LoadExternalScript();

    

// useEffect(()=>{require('../assets/js/main.js')},[])

  return (

    
     <>
     
      <div id="page">
       
        <div className="header-section header-transparent sticky-header section">
            <div className="header-inner">
                <div className="container position-relative">
                    <div className="row justify-content-between align-items-center">

                    
                       <div className="col-xl-2 col-auto order-0">
                        <div className="header-logo">
                                     
                                           < Link  to="/" onClick={() => {window.location.href="/"}}>
                                 {/* <img src={darklogo} alt="dark-logo's logo"/> */}
                                    {/* <img src={iicon} alt="Anon's logo" width="120" height="36" /> */}
                                     <img src={lightlogo} alt="dark-logo's logo"/>
                                   </Link>
                                </div>
                            </div>

                        
                        <div className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                            <div className="menu-column-area d-none d-xl-block position-static">
                                <nav className="site-main-menu">
                                    <ul>
                                              <li>
                                                  < Link  to="/" onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"   onClick={() => {window.location.href="/About"}}>   <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                              </li>
                                                  <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>

                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact" className="active"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                              </li>
                                                     <li>
                                                    < Link  to="/Login"  onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                            <div className="header-search-area ml-xl-7 ml-0">

                              
                                 <div className="header-search">
                                    <a href="javascript:void(0)" className="header-search-toggle"></a>
                                </div>
                               
                            </div>
                           
                            <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
                                <button className="toggle">
                                    <i className="icon-top"></i>
                                    <i className="icon-middle"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                           
                        </div>
                        
                    

                    </div>
                </div>
            </div>
        </div>
     
        
       
        <div className="page-title-section section section-padding-top" data-overlay="0.7" data-bg-image="assets/images/bg/breadcrumb-bg.jpg">
            
           
        </div>
        
        <div className="section section-padding-t90-b100">
            <div className="container shape-animate">
               
                <div className="section-title text-center mb-lg-12 mb-sm-8 mb-xs-8" data-aos="fade-up">
                          <h2 className="title">
                              BVCPartners LP is established and respected full-service
                          
                          </h2>
                    <p className="sub-title">

                               Audit, Tax Management, Business Process Management & Re-engineering, Investment Advisory Services, Human Capital Management & Development, and Cyber Security & IT Services. Our commitment to our clients has enabled the firm's continued
                              growth and success in developing and maintaining strong professional relationships.
                    </p>
                </div>
               

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                    <div className="col mb-6" data-aos="fade-up">
                      
                        <div className="contact-info" data-vivus-hover>
                            <div className="icon">
                                <img className="svgInject" src="assets/images/svg/linea/linea-basic-map.svg" alt=""></img>
                            </div>
                            <div className="info">
                                <h4 className="title">Our Locations</h4>
                                <span className="info-text">Suite A40, Shakir Plaza, No 3, Michika Street,Off Ahmadu Bello Way, Area 11, Garki, Abuja
                                </span>
                            </div>
                        </div>
                
                    </div>
                    <div className="col mb-6" data-aos="fade-up">
                       
                        <div className="contact-info" data-vivus-hover>
                            <div className="icon">
                                <img className="svgInject" src="assets/images/svg/linea/linea-basic-message-txt.svg" alt=""></img>
                            </div>
                            <div className="info">
                                <h4 className="title">Give Us A Call</h4>
                                <span className="info-text"> 
                                    +2348117390319</span>
                            </div>
                        </div>
                       
                    </div>
                    <div className="col mb-6" data-aos="fade-up">
                       
                        <div className="contact-info" data-vivus-hover>
                            <div className="icon">
                                <img className="svgInject" src="assets/images/svg/linea/linea-basic-mail-open-text.svg" alt=""></img>
                            </div>
                            <div className="info">
                                <h4 className="title"> Help Desk</h4>
                                <span className="info-text">
                                    <a href="#">info@bvcpartners.com.ng</a>
                                    <br></br>
                                    <a href="#"></a>
                                </span>
                            </div>
                        </div>
                       
                    </div>
                </div>

              
                <div className="shape shape-1 scene">
                    <span data-depth="4"><img src="assets/images/shape-animation/video-shape-1.png" alt="shape"></img></span>
                </div>
            

            </div>
        </div>
       
        <div className="google-map-area section text-center section-padding-bottom">
            <div className="container" data-aos="fade-up">
                <div className="contact-map-area">
                    

                    <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.307062389798!2d7.4971912394640166!3d9.035728791063248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0b8d89a726a9%3A0x40991085c6e1e49c!2sShakir%20Plaza!5e0!3m2!1sen!2sng!4v1706351121771!5m2!1sen!2sng" ></iframe>
                </div>
            </div>
        </div>
       
        <div className="contact-form-section section section-padding-t90-b100" data-bg-color="#f8faff">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                       
                        <div className="section-title text-center" data-aos="fade-up">
                            <h2 className="title fz-32">Let’s talk about your project</h2>
                            <p className="sub-title">We have made it easy for clients to reach us
                                and get their solutions weaved</p>
                        </div>
                       
                        <div className="contact-form" data-aos="fade-up">
                            <form action="assets/php/contact-mail.php" id="contact-form" method="post">
                                <div className="row mb-n6">
                                    <div className="col-md-6 col-12 mb-6">
                                        <input type="text" placeholder="Your Name *" name="name"></input>
                                    </div>
                                    <div className="col-md-6 col-12 mb-6">
                                        <input type="email" placeholder="Email *" name="email"></input>
                                    </div>
                                    <div className="col-md-12 col-12 mb-6">
                                        <input type="text" placeholder="Subject *" name="subject"></input>
                                    </div>
                                    <div className="col-12 mb-6">
                                        <textarea name="message" placeholder="Message"></textarea>
                                    </div>
                                    <div className="col-12 text-center mb-6">
                                        <button className="btn btn-primary btn-hover-secondary">Submit</button>
                                    </div>
                                </div>
                            </form>
                            <p className="form-messege"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       


       <div className="footer-section section" data-bg-color="#030e22">
            <div className="container">
        
                
                <div className="row mb-lg-14 mb-md-10 mb-6">
        
                    
                          
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                        
                            <div className="footer-widget-content">
                                <div className="content">
        
        
                                    <p><a href="#">+2348117390319</a></p>
                                    <p><a href="#">info@bvcpartners.com.ng</a> </p>
                                </div>
                                <div className="footer-social-inline">
                                    
 <a href="https://www.facebook.com/bvcpartners.com.ng?mibextid=ZbWKwL/"><i className="fab fa-facebook-square"></i></a>
                                         <a href=" https://www.instagram.com/bvcpartners?utm_source=qr&igsh=YnU0aHJveWxnbXhr/"><i className="fab fa-instagram"></i></a>

                                   <a href="https://www.linkedin.com/company/bola-vaughan-co-chartered-accountants/"><i className="fab fa-linkedin-in"></i></a>
                                    
        
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Services</h4>
                            <div className="footer-widget-content">
                                <ul>
                                          <li>
                                    < Link  to="/Sdetails_2"onClick={() => {window.location.href="/Sdetails_2"}}>  <span className="menu-text">Tax Mgt Services</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Audit Services</span></Link>
                                          </li>
                                               <li>
                                    < Link  to="/Sdetails_6"onClick={() => {window.location.href="/Sdetails_6"}}>  <span className="menu-text">Human Capital Mgt & Development</span></Link>         
                                          </li>
                                          <li>
                                    < Link  to="/Sdetails_5"onClick={() => {window.location.href="/Sdetails_5"}}>  <span className="menu-text">Cyber Security & IT Services</span></Link>         
                                   </li>
                                          
                                   
                                           <li>
                                    < Link  to="/Sdetails_3"onClick={() => {window.location.href="/Sdetails_3"}}>  <span className="menu-text">BPM</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Investment Advisory Services</span></Link>
                                          </li>
                                            <li>
                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules ERP</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Subscription_plan"  onClick={() => {window.location.href="/Subscription_plan"}}> <span className="menu-text">Subscription ERP </span></Link>
                                          </li>
                                         
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">About</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Features</a></li>
                                    <li><a href="#">Our Partners</a></li>
                                    <li><a href="#">Affiliate Program</a></li>
                                          <li><a href="#">Pricing</a></li>
                                          
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Contact</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Support Ticket</a></li>
                                    <li><a href="#">Help Desk</a></li>
                                    <li><a href="#">Live Chat</a></li>
                                    <li><a href="#">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
        
                </div>
                
                <div className="row">
                    <div className="col">
                        <p className="copyright">Copyright &#169; 2024 BVCPartners LP. All Rights Reserved  </p>
                    </div>
                </div>
               
        
            </div>
        </div>


        
        <a href="#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up"></i>
            <i className="arrow-bottom fal fa-long-arrow-up"></i>
        </a>
       
    </div>

    <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <a href="index.html"><img src="assets/images/logo/light-logo.png" alt=""></img></a>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle">
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <nav className="site-mobile-menu">
                 <ul>
                                              <li>
                                                  < Link  to="/" onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                              </li>
                                  <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>

                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact" className="active"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                              </li>
                                     <li>
                                                    < Link  to="/Login"  onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                    </ul>
                
                </nav>
            </div>
        </div>
    </div>
      </>
   
    
  );
}

export default Contact;
