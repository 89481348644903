import React, { useState, useEffect } from 'react';
import '../assets/js/main.js'
//import React, { createContext, ReactNode, useContext, useState } from 'react';

import logo from './logo.svg';
//import { Suspense, lazy, useEffect } from "react";

//import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

//import darklogo from "./assets/images/logo/light-logo.png";
import lightlogo from "../assets/images/logo/light-logo.png";    

import shapeanimation from "../assets/images/shape-animation/video-shape-1.png";
import skillvideo from "../assets/images/video/skill-video.jpg";
import homeoneabout1 from "../assets/images/about/home-one-about/home_agency_about_1.jpg";
import homeoneabout2 from "../assets/images/about/home-one-about/home_agency_about_2.jpg";
import shapeanimation2 from "../assets/images/shape-animation/about-shape-1.png";
import about5 from "../assets/images/about/about-5.jpg";
import about6 from "../assets/images/about/about-6.jpg";
import blog1 from "../assets/images/blog/370/blog-1.jpg";
import blog2 from "../assets/images/blog/370/blog-2.jpg";
import blog3 from "../assets/images/blog/370/blog-3.jpg";
import lineabasic from "../assets/images/svg/linea/linea-basic-map.svg";
import lineabasicmessage from "../assets/images/svg/linea/linea-basic-message-txt.svg";
import lineabasicmail from "../assets/images/svg/linea/linea-basic-mail-open-text.svg";
import contactshape from "../assets/images/shape-animation/contact-shape.png";                       


//import arrowupright from "./assets/images/icons/arrow-up-right.svg";
import type { FC } from "react";
import { Link } from 'react-router-dom';

<a href="https://api.whatsapp.com/send?phone=1234567890" target='_blank'>Contact Us on WhatsApp</a>
function Home() {

 
   // useExternalScript('public/assets/js/main2.js');
    
const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
     // externalScript.onerror = loadError;
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = '../assets/js/main.js';
};
    LoadExternalScript();












  return (





<>
    
    

    <div id="page" className="section">
        

    <div className="header-section header-transparent sticky-header section">
        <div className="header-inner">
            <div className="container position-relative">
                <div className="row justify-content-between align-items-center">

                   
                    <div className="col-xl-2 col-auto order-0">
                        <div className="header-logo">
                                     
                                          < Link  to="/" onClick={() => {window.location.href="/"}}>
                                 {/* <img src={darklogo} alt="dark-logo's logo"/> */}
                                    {/* <img src={iicon} alt="Anon's logo" width="120" height="36" /> */}
                                     <img src={lightlogo} alt="dark-logo's logo"/>
                                   </Link>
                                </div>
                            </div>
                      
                        <div
                            className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                            <div className="menu-column-area d-none d-xl-block position-static">
                                <nav className="site-main-menu">
                                   <ul>
                                              <li>
                                                  < Link  to="/" className="active" onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                              </li>
                                              <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>




                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                              </li>
                                                  <li>
                                                    < Link  to="/Login"  onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                           
                            <div className="header-search-area ml-xl-7 ml-0">

                               
                               <div className="header-search">
                                    <a href="javascript:void(0)" className="header-search-toggle"></a>
                                </div>
                                
                            </div>
                           
                                  <div className="header-mobile-menu-toggle
                            onClick={toggleMobileMenu}
                            d-xl-none ml-sm-2">
                                <button className="toggle">
                                    <i className="icon-top"></i>
                                    <i className="icon-middle"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                           
                        </div>
                        

                       
                       

                    </div>
                </div>
            </div>
        </div>
       
        <div className="main-search-active">
            <div className="sidebar-search-icon">
                <button className="search-close"><i className="pe-7s-close"></i></button>
            </div>
            <div className="sidebar-search-input">
                <form action="#">
                    <div className="form-search">
                        <input id="search" className="input-text" value="" placeholder="" type="search"></input>
                            <button>
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                </form>
                <p className="form-description">Hit enter to search or ESC to close</p>
            </div>
        </div>
       
        <div className="intro-slider-wrap section">
            <div className="intro-slider swiper-container">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="intro-section section overlay" data-bg-image="assets/images/hero-image/hero-1.jpg">

                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                    
                                     
                                    <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">We are Tax Managers </h2> */}
                                              <h2 className="title">< Link   to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}>We are Auditors  </Link></h2>
                                            <div className="desc">
                                                < Link   to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}>
                                                <p>We separate ourselves from our competition by Extensive Partner
                                                    involvement on each engagement Manager and/or Partner always on site
                                                    during fieldwork Consistent and experienced staff
                                                          </p>
                                                           
                                                      </Link>
                                                       <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">
                               < Link   to="/Sdetails_1"  className="active"  onClick={() => {window.location.href="/Sdetails_1"}}>   <span className="menu-text">More...</span></Link>
                                         
                                </a>
                                           

          
    
                                                  </div>

                                                  
                                              </div>
                                               <div className="des">
                                              
                                               <center>    <h3  className="des">...Simplifying Complexities...</h3> </center></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="swiper-slide">
                    
                        <div className="intro-section section overlay" data-bg-image="assets/images/hero-image/hero-2.jpg">

                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                    
                                    <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">We are Tax Managers </h2> */}
                                              <h2 className="title">< Link   to="/Sdetails_2"  onClick={() => {window.location.href="/Sdetails_2"}}>We are Tax Managers </Link></h2>
                                            <div className="desc">
                                                < Link   to="/Sdetails_2"  onClick={() => {window.location.href="/Sdetails_1"}}>
                                                <p>We separate ourselves from our competition by Extensive Partner
                                                    involvement on each engagement Manager and/or Partner always on site
                                                    during fieldwork Consistent and experienced staff
                                                      </p>
                                                      </Link>
                                                       <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">
                               < Link   to="/Sdetails_2"  className="active"  onClick={() => {window.location.href="/Sdetails_2"}}>   <span className="menu-text">More...</span></Link>
                                         
                                                      </a>
                                                        
                                            </div>
                                          
                                              </div>
                                              
                                    </div>

                                            <div className="des">
                                              
                                               <center>    <h3  className="des">...Simplifying Complexities...</h3> </center></div>
                                  

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="intro-section section overlay" data-bg-image="assets/images/hero-image/hero-3.jpg">

                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                 
                                    
                                    
                                    <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">We are Tax Managers </h2> */}
                                              <h2 className="title">< Link   to="/Sdetails_3"  onClick={() => {window.location.href="/Sdetails_3"}}>We are Business Process Managers </Link></h2>
                                            <div className="desc">
                                                < Link   to="/Sdetails_3"  onClick={() => {window.location.href="/Sdetails_3"}}>
                                                <p>We separate ourselves from our competition by Extensive Partner
                                                    involvement on each engagement Manager and/or Partner always on site
                                                    during fieldwork Consistent and experienced staff
                                                      </p>
                                                      </Link>
                                                       <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">
                               < Link   to="/Sdetails_3"  className="active"  onClick={() => {window.location.href="/Sdetails_3"}}>   <span className="menu-text">More...</span></Link>
                                         
                                                      </a>
                                                         
                                            </div>
                                        
                                              </div>
                                                <div className="des">
                                              
                                               <center>    <h3  className="des">...Simplifying Complexities...</h3> </center></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                          </div>
                          <div className="swiper-slide">
                        <div className="intro-section section overlay" data-bg-image="assets/images/hero-image/hero-4.jpg">

                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                    


                                     <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">We are Tax Managers </h2> */}
                                              <h2 className="title">< Link   to="/Sdetails_4"  onClick={() => {window.location.href="/Sdetails_4"}}>We are Investment Advisers </Link></h2>
                                            <div className="desc">
                                                < Link   to="/Sdetails_4"  onClick={() => {window.location.href="/Sdetails_4"}}>
                                                <p>We separate ourselves from our competition by Extensive Partner
                                                    involvement on each engagement Manager and/or Partner always on site
                                                    during fieldwork Consistent and experienced staff
                                                      </p>
                                                      </Link>
                                                       <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">
                               < Link   to="/Sdetails_4"  className="active"  onClick={() => {window.location.href="/Sdetails_4"}}>   <span className="menu-text">More...</span></Link>
                                         
                                                      </a>
                                                       
                                            </div>
                                        
                                              </div>
                                                <div className="des">
                                              
                                               <center>    <h3  className="des">...Simplifying Complexities...</h3> </center></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="intro-section section overlay" data-bg-image="assets/images/hero-image/hero-5.jpg">

                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                    
                                    


                                     <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">We are Tax Managers </h2> */}
                                              <h2 className="title">< Link   to="/Sdetails_5"  onClick={() => {window.location.href="/Sdetails_5"}}>We are Cyber Security and IT Services Consultants </Link></h2>
                                            <div className="desc">
                                                < Link   to="/Sdetails_5"  onClick={() => {window.location.href="/Sdetails_5"}}>
                                                <p>We separate ourselves from our competition by Extensive Partner
                                                    involvement on each engagement Manager and/or Partner always on site
                                                    during fieldwork Consistent and experienced staff
                                                      </p>
                                                      </Link>
                                                       <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">
                               < Link   to="/Sdetails_5"  className="active"  onClick={() => {window.location.href="/Sdetails_5"}}>   <span className="menu-text">More...</span></Link>
                                         
                                </a>
                                  
                                            </div>
                                        
                                              </div>
                                                <div className="des">
                                              
                                               <center>    <h3  className="des">...Simplifying Complexities...</h3> </center></div>
                                    </div>
                                  

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="intro-section section overlay" data-bg-image="assets/images/hero-image/hero-6.jpg">

                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                 
                                    
                                  <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">We are Tax Managers </h2> */}
                                              <h2 className="title">< Link   to="/Sdetails_6"  onClick={() => {window.location.href="/Sdetails_6"}}>We are Human Capital Managers </Link></h2>
                                            <div className="desc">
                                                < Link   to="/Sdetails_6"  onClick={() => {window.location.href="/Sdetails_6"}}>
                                                <p>We separate ourselves from our competition by Extensive Partner
                                                    involvement on each engagement Manager and/or Partner always on site
                                                    during fieldwork Consistent and experienced staff
                                                      </p>
                                                      </Link>
                                                       <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">
                               < Link   to="/Sdetails_6"  className="active"  onClick={() => {window.location.href="/Sdetails_6"}}>   <span className="menu-text">More...</span></Link>
                                         
                                </a>  
                                            </div>
                                        
                                              </div>
                                 <div className="des">
                                              
                                               <center>    <h3  className="des">...Simplifying Complexities...</h3> </center></div>
                                    </div>

                                </div>
                            </div>


                                     
                                  

                                  

                        </div>
                          </div>
                          <div className="swiper-slide">
                        <div className="intro-section section overlay" data-bg-image="assets/images/hero-image/hero-7.jpg">

                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                 
                                    

                                          <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">We are Tax Managers </h2> */}
                                              <h2 className="title">< Link   to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}>We are Chartered Accountants </Link></h2>
                                            <div className="desc">
                                                < Link   to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}>
                                                <p>We separate ourselves from our competition by Extensive Partner
                                                    involvement on each engagement Manager and/or Partner always on site
                                                    during fieldwork Consistent and experienced staff
                                                      </p>
                                                      </Link>
                                                       <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">
                               < Link   to="/Sdetails_7"  className="active"  onClick={() => {window.location.href="/Sdetails_1"}}>   <span className="menu-text">More...</span></Link>
                                         
                                </a>  
                                            </div>
                                        
                                              </div>
                                                <div className="des">
                                              
                                               <center>    <h3  className="des">...Simplifying Complexities...</h3> </center></div>
                                    </div>
                                  

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="home-slider-prev swiper-button-prev main-slider-nav"><i className="fal fa-angle-left"></i></div>
                <div className="home-slider-next swiper-button-next main-slider-nav"><i className="fal fa-angle-right"></i>
                </div>
            </div>
        </div>
      


        <div className="section section-padding-t90 section-padding-bottom-190">
            <div className="container">

                
                {/* <div className="section-title text-center" data-aos="fade-up">
                    <h2 className="title">BVCPartners LP</h2>
                    <p className="sub-title">Registered Chartered Accountant firm certified by the
                        Institute of Chartered
                        <br></br> Accountants of Nigeria.
                        </p>



                </div>
                */}
                <div className="row">

                    
                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image js-tilt">
                               
                                <img src={homeoneabout1} alt=""></img>
                                </div>
                            <div className="about-image js-tilt">
                                <img src={homeoneabout2} alt=""></img>
                                </div>
                            
                            <div className="shape shape-1 scene">
                                <span data-depth="1"><img src={shapeanimation2}  alt=""></img></span>
                            </div>
                           
                        </div>
                    </div>
                
                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up">
                    
                        <div className="about-content-area">
                           
                            <div className="section-title-two">
                                <span className="sub-title">Every day brings new challenges</span>

                                <h3 className="title">...BVCPartners LP...</h3>
                            </div>
                         

                            <p>BVCPartners LP is a. We are an established and respected full-service Audit, Tax Management, Business Process Management & Re-engineering, Investment Advisory on the Capital & Commodity Market, Human Capital Management & Development, and Cyber Security & IT Services. Our commitment to our clients has enabled the firm's continued growth and success in developing and maintaining strong professional relationships.....
                            </p>
                            <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">
                               < Link   to="/About"  className="active"  onClick={() => {window.location.href="/About"}}>   <span className="menu-text">About Us</span></Link>
                                         
                                </a>
                        </div>
                       
                    </div>
                   

                </div>
               

            </div>
        </div>
       
        <div className="section section-padding" data-bg-color="#f8faff">

            <div className="container">

                <div className="row">

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up">
                       
                        <div className="skill-with-video-content">
                           
                            <div className="section-title-two mb-8">
                                <span className="sub-title">Development that converts and delivers</span>
                                <h3 className="title">We offer the tools and skills that your company deserves</h3>
                            </div>
                          
                            <ul className="agency-list">
                                <li className="item">
                                    <div className="icon">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div className="text">Financial Audit Services</div>
                                </li>
                                <li className="item">
                                    <div className="icon">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div className="text">Bank Transactions Reconciliation Services</div>
                                </li>
                                <li className="item">
                                    <div className="icon">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div className="text">Accounting System Setup & Training</div>
                                </li>
                                <li className="item">
                                    <div className="icon">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div className="text">Business Consulting</div>
                                </li>
                            </ul>
                            

                            <a href="#" className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4">  
                               < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                  </a>
                        </div>

                      
                    </div>

                    <div className="offset-xl-1 col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                    
                        <div className="video-popup-area">
                          
                            <div className="skill-video" data-aos="fade-up">
                            
                                <img className="image" src={skillvideo} alt="video popup"></img>
                                    <a href="https://www.youtube.com/watch?v=eS9Qm4AOOBY" className="icon video-popup">
                                        <i className="fas fa-play"></i>
                                </a>
                            </div>
                          
                            <div className="shape shape-1 scene">
                                <span data-depth="1">
                                    <img src={shapeanimation} alt="shape-animation's logo"/>
                                    
                                    </span>
                            </div>
                        
                        </div>
                       
                    </div>

                </div>

            </div>

        </div>
   
        <div className="section section-padding-top section-padding-bottom-160">
            <div className="container">

               
                <div className="row">

                   
                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image js-tilt">
                                <img src={about5} alt=""></img>
                                </div>
                            <div className="about-image js-tilt">
                                <img src={about6} alt=""></img>
                            
                                </div>
                    
                            <div className="shape shape-1 scene">
    
                                 <img src={shapeanimation2} alt=""></img>
                            </div>
                           
                        </div>
                    </div>
                   
                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up">
                       
                        <div className="about-content-area">
                           
                            <div className="section-title-two">
                                <span className="sub-title">Innovative & cutting-edge technology</span>
                                <h3 className="title">We use latest technologies <br></br> that are proven and practical</h3>
                            </div>
                       

                            <p>Our goal is to make the process easier for you. We provide our clients with the right tools for
                                IT Audit Services, ISM, & Business Consulting, Forensic, Accounting, content and marketing.</p>

                            <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">Get
                                Started</a>
                        </div>
                       
                    </div>
                  

                </div>
               
            </div>
        </div>
      
        <div className="section section-padding-t90 ag-masonary-wrapper">
            <div className="container-fluid px-0">
             
                <div className="section-title text-center" data-aos="fade-up">
                    <h2 className="title fz-32">Great brands deserve even greater creative works</h2>
                </div>
              
                <div className="messonry-button text-center mb-lg-13 mb-md-13 mb-6" data-aos="fade-up">
                    <button data-filter="*" className="is-checked"><span className="filter-text">All</span></button>
                    <button data-filter=".cat-1"><span className="filter-text">Forensic</span></button>
                    <button data-filter=".cat-2"><span className="filter-text">IT Audit Services</span></button>
                    <button data-filter=".cat-3"><span className="filter-text">ISM</span> </button>
                    <button data-filter=".cat-4"><span className="filter-text">IT & Software</span></button>
                    <button data-filter=".cat-5"><span className="filter-text">Accounting</span></button>
                </div>
               
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 g-0 mesonry-list">
                    <div className="resizer col"></div>
                  
                    <div className="col cat-1 cat-3">
                        <div className="single-portfolio">
                            <div className="thumbnail">
                                <img className="img-fluid" src="assets/images/portfolio/portfolio-1.jpg" alt="Portfolio-01"></img>
                            </div>
                            <div className="content">
                                <h5 className="title"><a href="#">Project Name #1 <img
                                            src="assets/images/icons/arrow-up-right.svg" alt=""></img></a></h5>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col cat-2 cat-5">
                        <div className="single-portfolio">
                            <div className="thumbnail">
                                <img className="img-fluid" src="assets/images/portfolio/portfolio-2.jpg" alt="Portfolio-01"></img>
                            </div>
                            <div className="content">
                                <h5 className="title"><a href="#">Project Name #1 <img
                                            src="assets/images/icons/arrow-up-right.svg" alt=""></img></a></h5>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col cat-3 cat-2">
                        <div className="single-portfolio">
                            <div className="thumbnail">
                                <img className="img-fluid" src="assets/images/portfolio/portfolio-3.jpg" alt="Portfolio-01"></img>
                            </div>
                            <div className="content">
                                <h5 className="title"><a href="#">Project Name #1 <img
                                            src="assets/images/icons/arrow-up-right.svg" alt=""></img></a></h5>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col cat-4 cat-3">
                        <div className="single-portfolio">
                            <div className="thumbnail">
                                <img className="img-fluid" src="assets/images/portfolio/portfolio-4.jpg" alt="Portfolio-01"></img>
                            </div>
                            <div className="content">
                                <h5 className="title"><a href="#">Project Name #1 <img
                                            src="assets/images/icons/arrow-up-right.svg" alt=""></img></a></h5>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col cat-5">
                        <div className="single-portfolio">
                            <div className="thumbnail">
                                <img className="img-fluid" src="assets/images/portfolio/portfolio-5.jpg" alt="Portfolio-01"></img>
                            </div>
                            <div className="content">
                                <h5 className="title"><a href="#">Project Name #1 <img
                                            src="assets/images/icons/arrow-up-right.svg" alt=""></img></a></h5>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col cat-2">
                        <div className="single-portfolio">
                            <div className="thumbnail">
                                <img className="img-fluid" src="assets/images/portfolio/portfolio-6.jpg" alt="Portfolio-01"></img>
                            </div>
                            <div className="content">
                                <h5 className="title"><a href="#">Project Name #1 <img
                                            src="assets/images/icons/arrow-up-right.svg" alt=""></img></a></h5>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
     
        {/* <div className="section section-padding-t90-b100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8">
                       
                        <div className="section-title-two mb-lg-12 mb-md-6" data-aos="fade-up">
                            <span className="sub-title">Latest marketing news and management insights</span>
                            <h3 className="title">We bring you all the latest marketing news and the most interesting stats
                                for marketers</h3>
                        </div>
                       
                    </div>
                    <div className="col-lg-4">
                        <div className="section-button text-lg-right text-left mb-md-8 mb-sm-6 mb-6" data-aos="fade-up">
                            <a href="#" className="btn btn-primary ">Visit Blog</a>
                        </div>
                    </div>
                </div>

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                    <div className="col mb-6" data-aos="fade-up">
                    
                        <div className="blog">
                            <div className="thumbnail">
                                <a href="blog-details.html" className="image"><img src={blog1}
                                    alt="Blog Image"></img></a>
                            </div>
                            <div className="info">
                                <h3 className="title"><a href="blog-details.html">How your sales can work together in
                                    account-based marketing</a></h3>
                                <p className="desc">Tom Hileman leads an award-winning agency to deliver high-touch,
                                    data-driven marketing solutions for leading organizations...</p>
                                <a href="#" className="link "> <mark>Read More</mark> </a>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col mb-6" data-aos="fade-up">
                        
                        <div className="blog">
                            <div className="thumbnail">
                                <a href="blog-details.html" className="image"><img src={blog2}
                                    alt="Blog Image"></img></a>
                            </div>
                            <div className="info">
                                <h3 className="title"><a href="blog-details.html">The six things marketers need to
                                    understand about DTC marketing</a></h3>
                                <p className="desc">As brick-and-mortar retail has winnowed to bare existence,
                                    direct-to-consumer (DTC) marketing is emerging as both a need...</p>
                                <a href="#" className="link "> <mark>Read More</mark> </a>
                            </div>
                        </div>
                       
                    </div>
                    <div className="col mb-6" data-aos="fade-up">
                    
                        <div className="blog">
                            <div className="thumbnail">
                                <a href="blog-details.html" className="image"><img src={blog3}
                                    alt="Blog Image"></img></a>
                            </div>
                            <div className="info">
                                <h3 className="title"><a href="blog-details.html">Eleven top tips for developing agile
                                    marketing strategies that work</a></h3>
                                <p className="desc">Tom Hileman leads an award-winning agency to deliver high-touch,
                                    data-driven marketing solutions for leading organizations...</p>
                                <a href="#" className="link "> <mark>Read More</mark> </a>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        */}


       
        <div className="section section-padding contact-section" data-overlay="0.7" data-bg-image="assets/images/bg/contact-bg.jpg">

            <div className="container">
                <div className="row row-cols-lg-2 row-cols-1 align-items-center">
                    <div className="col" data-aos="fade-up">
                       
                        <div className="contact-Information mr-xl-7">
                           
                            <div className="section-title-two color-light">
                                <span className="sub-title">Let’s find out how to work together</span>
                                <h3 className="title">When you partner with us,  you get more than a contract</h3>
                            </div>
                          

                            
                            <div className="contact-info info-light" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={lineabasic} alt="">
                                    </img></div>
                                <div className="info">
                                    <h4 className="title">Our Locations</h4>
                                    <span className="info-text"> Suite A40, Shakir Plaza, No 3, Michika Street,Off Ahmadu Bello Way, Area 11, Garki, Abuja.</span>
                                </div>
                            </div>
                           
                            <div className="contact-info info-light" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={lineabasicmessage} alt="">
                                    </img></div>
                                <div className="info">
                                    <h4 className="title">Give Us A Call</h4>
                                    <span className="info-text"> +2348117390319</span>
                                </div>
                            </div>
                            
                            <div className="contact-info info-light" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={lineabasicmail } alt="">
                                    </img></div>
                                <div className="info">
                                    <h4 className="title"> Help Desk</h4>
                                    <span className="info-text">
                                        <a href="#">info@bvcpartners.com.ng</a>
                                    </span>
                                </div>
                            </div>
                           
                        </div>
                      
                    </div>
                    <div className="col mt-lg-0 mt-md-10 mt-8" data-aos="fade-up">
                       
                        <div className="contact-form-area">
                            
                            <div className="section-title text-center mb-7" data-aos="fade-up">
                                <h2 className="title fz-28">Let’s talk about your project</h2>
                                <p className="sub-title">We have made it easy for clients to reach us
                                    and get their solutions weaved</p>
                            </div>
                           
                            <form action="#" id="contact-form" method="post">
                                <div className="row mb-n4">
                                    <div className="col-md-12 col-12 mb-4">
                                        <input type="text" placeholder="Your Name *" name="name">
                                        </input></div>
                                    <div className="col-md-12 col-12 mb-4">
                                        <input type="email" placeholder="Email *" name="email">
                                        </input></div>
                                    <div className="col-12 mb-6">
                                        <textarea name="message" placeholder="Message"></textarea>
                                    </div>
                                    <div className="col-12 text-center mb-4">
                                        <button className="btn btn-primary btn-hover-secondary">Send</button>
                                    </div>
                                </div>
                            </form>
                            <p className="form-messege"></p>

                           
                            <div className="shape shape-1 scene">
                                <span data-depth="1">
                                    <img src={contactshape} alt="">
                                    </img></span>
                            </div>
                            
                        </div>
                       
                    </div>
                </div>
            </div>

            <div className="shape shape-1 scene">
                <span data-depth="1">
                    <img src={contactshape} alt="">
                    </img></span>
            </div>
           

        </div>
   

        <div className="footer-section section" data-bg-color="#030e22">
            <div className="container">
        
                
                <div className="row mb-lg-14 mb-md-10 mb-6">
        
                    
                          
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                        
                            <div className="footer-widget-content">
                                <div className="content">
        
        
                                    <p><a href="#">+2348117390319</a></p>
                                    <p><a href="#">info@bvcpartners.com.ng</a> </p>
                                </div>
                                <div className="footer-social-inline">
                                    
 <a href="https://www.facebook.com/bvcpartners.com.ng?mibextid=ZbWKwL/"><i className="fab fa-facebook-square"></i></a>
                                         <a href=" https://www.instagram.com/bvcpartners?utm_source=qr&igsh=YnU0aHJveWxnbXhr/"><i className="fab fa-instagram"></i></a>

                                   <a href="https://www.linkedin.com/company/bola-vaughan-co-chartered-accountants/"><i className="fab fa-linkedin-in"></i></a>
                                    
        
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Services</h4>
                            <div className="footer-widget-content">
                                <ul>
                                          <li>
                                    < Link  to="/Sdetails_2"onClick={() => {window.location.href="/Sdetails_2"}}>  <span className="menu-text">Tax Mgt Services</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Audit Services</span></Link>
                                          </li>
                                               <li>
                                    < Link  to="/Sdetails_6"onClick={() => {window.location.href="/Sdetails_6"}}>  <span className="menu-text">Human Capital Mgt & Development</span></Link>         
                                          </li>
                                          <li>
                                    < Link  to="/Sdetails_5"onClick={() => {window.location.href="/Sdetails_5"}}>  <span className="menu-text">Cyber Security & IT Services</span></Link>         
                                   </li>
                                          
                                   
                                           <li>
                                    < Link  to="/Sdetails_3"onClick={() => {window.location.href="/Sdetails_3"}}>  <span className="menu-text">BPM</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Investment Advisory Services</span></Link>
                                          </li>
                                            <li>
                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules ERP</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Subscription_plan"  onClick={() => {window.location.href="/Subscription_plan"}}> <span className="menu-text">Subscription ERP </span></Link>
                                          </li>
                                         
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">About</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Features</a></li>
                                    <li><a href="#">Our Partners</a></li>
                                    <li><a href="#">Affiliate Program</a></li>
                                          <li><a href="#">Pricing</a></li>
                                          
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Contact</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Support Ticket</a></li>
                                    <li><a href="#">Help Desk</a></li>
                                    <li><a href="#">Live Chat</a></li>
                                    <li><a href="#">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
        
                </div>
                
                <div className="row">
                    <div className="col">
                        <p className="copyright">Copyright &#169; 2024 BVCPartners LP. All Rights Reserved  </p>
                    </div>
                </div>
               
        
            </div>
        </div>











              

    <a href="#" className="scroll-top" id="scroll-top">
        <i className="arrow-top fal fa-long-arrow-up"></i>
        <i className="arrow-bottom fal fa-long-arrow-up"></i>
    </a>

          </div>
          
          <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <a href="index.html"><img src={lightlogo} alt=""></img></a>
                </div>
                      <div className="mobile-menu-close
                       onClick={toggleMobileMenu} ">
                    <button className="toggle">
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <nav className="site-mobile-menu">
                     <ul>
                                              <li>
                                                  < Link  to="/" className="active" onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                              </li>
                                              <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>






                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                        </li>
                                               <li>
                                                    < Link  to="/Login"  onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                    </ul>
                
                </nav>
            </div>
        </div>


              
          </div>
      </>


    
  );
}

export default Home;
