
import useta, { useEffect } from 'react';
import useState from 'react';
import logo from './logo.svg';
import { Link } from "react-router-dom";
import lightlogo from "../assets/images/logo/light-logo.png";    
import shapeanimation from "../assets/images/shape-animation/video-shape-1.png";
import skillvideo from "../assets/images/video/skill-video.jpg";
import homeoneabout1 from "../assets/images/about/home-one-about/home_agency_about_1.jpg";
import homeoneabout2 from "../assets/images/about/home-one-about/home_agency_about_2.jpg";
import shapeanimation2 from "../assets/images/shape-animation/about-shape-1.png";
import about5 from "../assets/images/about/about-5.jpg";
import about6 from "../assets/images/about/about-6.jpg";
import blog1 from "../assets/images/blog/370/blog-1.jpg";
import blog2 from "../assets/images/blog/370/blog-2.jpg";
import blog3 from "../assets/images/blog/370/blog-3.jpg";
import lineabasic from "../assets/images/svg/linea/linea-basic-map.svg";
import lineabasicmessage from "../assets/images/svg/linea/linea-basic-message-txt.svg";
import lineabasicmail from "../assets/images/svg/linea/linea-basic-mail-open-text.svg";
import contactshape from "../assets/images/shape-animation/contact-shape.png";                       



function About(this: any) {

 
   // useExternalScript('public/assets/js/main2.js');
    
const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
     // externalScript.onerror = loadError;
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = '../assets/js/main.js';
};
LoadExternalScript();


    


    
  return (
   
    
      <>
       
    <div id="page" className="section">
    
        <div className="header-section header-transparent sticky-header section">
            <div className="header-inner">
                <div className="container position-relative">
                    <div className="row justify-content-between align-items-center">

                        
                   <div className="col-xl-2 col-auto order-0">
                        <div className="header-logo">
                                     
                                           < Link  to="/" onClick={() => {window.location.href="/"}}>
                                 {/* <img src={darklogo} alt="dark-logo's logo"/> */}
                                    {/* <img src={iicon} alt="Anon's logo" width="120" height="36" /> */}
                                     <img src={lightlogo} alt="dark-logo's logo"/>
                                   </Link>
                                </div>
                            </div>
                        
                        <div className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                            <div className="menu-column-area d-none d-xl-block position-static">
                                <nav className="site-main-menu">
                                    <ul>
                                              <li>
                                                  < Link  to="/" onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  className="active"  onClick={() => {window.location.href="/About"}}>   <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                              </li>
                                              
                                                  <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>

                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                              </li>
                                              <li>
                                                    < Link  to="/Login"  onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            
                            <div className="header-search-area ml-xl-7 ml-0">

                                
                                <div className="header-search">
                                    <a href="javascript:void(0)" className="header-search-toggle"></a>
                                </div>
                               
                            </div>
                           

                            
                            <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
                                <button className="toggle">
                                    <i className="icon-top"></i>
                                    <i className="icon-middle"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                        
                        </div>
                    
                         
                       

                    </div>
                </div>
            </div>
        </div>
      



      
        <div className="page-title-section section section-padding-top" data-overlay="0.7" data-bg-image="assets/images/bg/breadcrumb-bg.jpg">
           
        </div>
        
        <div className="section section-padding-top section-padding-bottom-150">
            <div className="container">

                
                <div className="row">

                   
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                           
                            <div className="section-title-two">
                                <span className="sub-title">We think strategy</span>
                                <h3 className="title">BVCPartners LP</h3>
                                 <br></br>
                                We are Certified Accounting/Audit firm. BVCPartners LP is established and respected full-service Audit, Tax Management, Business Process Management & Re-engineering, Investment Advisory Services, Human Capital Management & Development, and Cyber Security & IT Services. Our commitment to our clients has enabled the firm's continued growth and success in developing and maintaining strong professional relationships. <br></br>
                            
                            <br></br>

                            <b>We separate ourselves from our competitors by</b><br></br>
                            • Extensive Partner involvement in each engagement <br></br>
                            • Manager and/or Partner always on-site during fieldwork <br></br>
                            • Consistent and experienced staff <br></br>
                           • Timeliness of communications <br></br>
                         • Proactive approach in addressing complex issues early in the engagement process <br></br>
                         • Availability to clients as a specialized resource <br></br>
                            • Professionalism with understanding
                            

                            </div>
                            

                            
                        </div>
                      


                    </div>
                 
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                <img src="assets/images/about/about-1.jpg" alt=""></img>
                            </div>
                            <div className="about-image js-tilt">
                                <img src="assets/images/about/about-2.jpg" alt=""></img>
                            </div>
                           
                            <div className="shape shape-1 scene">
                                <span data-depth="1"><img src="assets/images/shape-animation/video-shape-1.png" alt=""></img></span>
                            </div>
                            
                        </div>
                    </div>
                    



                    <div className="col-xl-12 col-lg-12 col-12" data-aos="fade-up">
                        
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                      <div className="content mb-5" data-aos="fade-up">
                                      <p>Our industry’s specialization includes governmental, not-for-profit, for-profit, and medium/small businesses in general.  
                                          </p>
                                        </div>


                                    <div className="section-title-two">
                                      <p> Our clients range from small organizations to organizations with over $200 billion in assets. We have access to the latest developments in Audit, Tax Management, Business Process Management & Re-engineering, Investment Advisory Services, Human Capital Management & Development, Cyber Security & IT Services, and the various rules and regulations that affect the different industries. Our membership in Professional Institute centers provides our firm with additional resources designed to enhance our consulting services and apply best practices to our engagements.   
                                          </p>
                                        </div>
                            <div className="section-title-two">
                            We have office location at Suite A40, Shakir Plaza, 3 Michika Street off Ahmadu Bello Way, Area 11, Garki, Abuja. However, some of our engagements are also performed out of the Abuja office.
We have NOT had any Disciplinary actions taken against, or pending against, the Firm. We believe our unique combination of technical expertise, combined with our philosophy of total client commitment throughout all phases of engagement provides our clients with the highest quality service and products available.
                
                    
                    
                            </div>
                          
                    
                    
                        </div>
                       
                    
                    
                    </div>
                </div>
                

            </div>
        </div>

        <div className="video-section section section-padding-150" data-overlay="0.7" data-bg-image="assets/images/bg/video-bg.jpg">

            <div className="container text-center">

            
                <div className="section-title color-light text-center mb-lg-14 mb-md-8 mb-6" data-aos="fade-up">
                    <h2 className="title">A full-service creative agency since 2007</h2>
                </div>
              
                <a href="https://www.youtube.com/watch?v=eS9Qm4AOOBY" className="play-btn icon video-popup"><i className="fas fa-play"></i></a>

            </div>

            
            <div className="shape shape-1 scene">
                <span data-depth="1">
                    <img src="assets/images/shape-animation/newsletter-shape.png" alt=""></img>
                </span>
            </div>
         

        </div>
       
        <div className="section section-padding-top section-padding-bottom-160">
            <div className="container">

               
                <div className="row">

                  
                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image js-tilt">
                                <img src="assets/images/about/about-3.jpg" alt=""></img>
                            </div>
                            <div className="about-image js-tilt">
                                <img src="assets/images/about/about-4.jpg"alt=""></img>
                            </div>
                          
                            <div className="shape shape-1 scene">
                                <span data-depth="1"><img src="assets/images/shape-animation/about-shape-1.png" alt=""></img></span>
                            </div>
                            
                        </div>
                    </div>
                  
                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up">
                       
                        <div className="about-content-area">
                            
                            <div className="section-title-two">
                                <span className="sub-title">Our solutions begin with brand research</span>
                                <h3 className="title">We’ve worked alongside <br></br> plenty of brands & startups</h3>
                            </div>      
                            

                            <p>Our team of designers and developers are perfectionists who love what they do – we thrive off pushing the boundaries of our clients’ expectations and our own capabilities.</p>

                            <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">Get Started</a>
                        </div>
                    
                    </div>
                   

                </div>
               

            </div>
        </div>
       

        
        {/* <div className="testimonial-section section section-padding-t90 section-padding-bottom" data-bg-color="#f8faff">
            <div className="container-fluid pl-xl-16 pl-lg-3 pl-md-3 pl-sm-3 pl-3 pr-xl-16 pr-lg-3 pr-md-3 pr-sm-3 pr-3">
               
                <div className="section-title text-center" data-aos="fade-up">
                    <h2 className="title fz-32">What our customers are saying about our services</h2>
                </div>
            

               
                      
                <div className="testimonial-slider swiper-container" data-aos="fade-up">
                    <div className="swiper-wrapper">

                        <div className="swiper-slide">
                           
                                  
                            <div className="static-testimonial mb-6">
                                <div className="testimonial-image">
                                    <img src="assets/images/testimonial/90/author-1.png" alt=""></img>
                                </div>
                                <div className="testimonial-content">
                                    <p>We are completely satisfied with the quality of service that we get. Workers were respectful, efficient, accommodating, cleaned up thoroughly.</p>
                                </div>
                                <div className="author-info">
                                    <div className="cite">
                                        <h6 className="name">Thomas Smith</h6>
                                        <span className="position">CEO at Flow</span>
                                    </div>
                                </div>
                            </div>
                          
                        </div>

                        <div className="swiper-slide">
                            
                            <div className="static-testimonial mb-6">
                                <div className="testimonial-image">
                                    <img src="assets/images/testimonial/90/author-2.png" alt=""></img>
                                </div>
                                <div className="testimonial-content">
                                    <p>We are completely satisfied with the quality of service that we get. Workers were respectful, efficient, accommodating, cleaned up thoroughly. </p>
                                </div>
                                <div className="author-info">
                                    <div className="cite">
                                        <h6 className="name">Eloise Smith</h6>
                                        <span className="position">CEO at Flow</span>
                                    </div>
                                </div>
                            </div>
                           
                        </div>

                        <div className="swiper-slide">
                            
                            <div className="static-testimonial mb-6">
                                <div className="testimonial-image">
                                    <img src="assets/images/testimonial/90/author-3.png" alt=""></img>
                                </div>
                                <div className="testimonial-content">
                                    <p>We are completely satisfied with the quality of service that we get. Workers were respectful, efficient, accommodating, cleaned up thoroughly.</p>
                                </div>
                                <div className="author-info">
                                    <div className="cite">
                                        <h6 className="name">Thomas Smith</h6>
                                        <span className="position">CEO at Flow</span>
                                    </div>
                                </div>
                            </div>
                           
                        </div>

                        <div className="swiper-slide">
                            
                            <div className="static-testimonial mb-6">
                                <div className="testimonial-image">
                                    <img src="assets/images/testimonial/90/author-3.png" alt=""></img>
                                </div>
                                <div className="testimonial-content">
                                    <p>I love their flexibility. Even when my request is too complicated to handle. they could still suggest something useful for me. </p>
                                </div>
                                <div className="author-info">
                                    <div className="cite">
                                        <h6 className="name">Florence Themes</h6>
                                        <span className="position">/ Multimedia Admin</span>
                                    </div>
                                </div>
                            </div>
                           
                        </div>

                    </div>
                    <div className="swiper-pagination"></div>
                </div>
               
            </div>
        </div> */}
    
        <div className="cta-section section section-padding-250">
            <div className="container text-center icon-up-down-animation">
               
                <div className="section-title text-center" data-aos="fade-up">
                    <h2 className="title fz-34">Let's talk about your project and see how we can work together</h2>
                    <p className="sub-title">Our team of designers, developers and creatives are perfectionists
                       <br></br> who love what they do and love where they work</p>
                </div>
                
                <a className="btn btn-primary btn-hover-secondary" href="#">Contact Us</a>

               
                <div className="shape shape-1">
                    <span>
                        <img src="assets/images/icon-animation/icon-1.png" alt=""></img>
                    </span>
                </div>
                <div className="shape shape-2">
                    <span>
                        <img src="assets/images/icon-animation/icon-2.png"alt=""></img>
                    </span>
                </div>
                <div className="shape shape-3">
                    <span>
                        <img src="assets/images/icon-animation/icon-3.png" alt=""></img>
                    </span>
                </div>
                <div className="shape shape-4">
                    <span>
                        <img src="assets/images/icon-animation/icon-4.png" alt=""></img>
                    </span>
                </div>
                <div className="shape shape-5">
                    <span>
                        <img src="assets/images/icon-animation/icon-5.png" alt=""></img>
                    </span>
                </div>
                <div className="shape shape-6">
                    <span>
                        <img src="assets/images/icon-animation/icon-6.png" alt=""></img>
                    </span>
                </div>
                <div className="shape shape-7">
                    <span>
                        <img src="assets/images/icon-animation/icon-7.png" alt=""></img>
                    </span>
                </div>
                <div className="shape shape-8">
                    <span>
                        <img src="assets/images/icon-animation/icon-8.png"alt=""></img>
                    </span>
                </div>
                <div className="shape shape-9">
                    <span>
                        <img src="assets/images/icon-animation/icon-9.png" alt=""></img>
                    </span>
                </div>
                
            </div>
        </div>
       

        <div className="footer-section section" data-bg-color="#030e22">
            <div className="container">
        
                
                <div className="row mb-lg-14 mb-md-10 mb-6">
        
                    
                          
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                        
                            <div className="footer-widget-content">
                                <div className="content">
        
        
                                    <p><a href="#">+2348117390319</a></p>
                                    <p><a href="#">info@bvcpartners.com.ng</a> </p>
                                </div>
                                <div className="footer-social-inline">
                                    
 <a href="https://www.facebook.com/bvcpartners.com.ng?mibextid=ZbWKwL/"><i className="fab fa-facebook-square"></i></a>
                                         <a href=" https://www.instagram.com/bvcpartners?utm_source=qr&igsh=YnU0aHJveWxnbXhr/"><i className="fab fa-instagram"></i></a>

                                   <a href="https://www.linkedin.com/company/bola-vaughan-co-chartered-accountants/"><i className="fab fa-linkedin-in"></i></a>
                                    
        
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Services</h4>
                            <div className="footer-widget-content">
                                <ul>
                                          <li>
                                    < Link  to="/Sdetails_2"onClick={() => {window.location.href="/Sdetails_2"}}>  <span className="menu-text">Tax Mgt Services</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Audit Services</span></Link>
                                          </li>
                                               <li>
                                    < Link  to="/Sdetails_6"onClick={() => {window.location.href="/Sdetails_6"}}>  <span className="menu-text">Human Capital Mgt & Development</span></Link>         
                                          </li>
                                          <li>
                                    < Link  to="/Sdetails_5"onClick={() => {window.location.href="/Sdetails_5"}}>  <span className="menu-text">Cyber Security & IT Services</span></Link>         
                                   </li>
                                          
                                   
                                           <li>
                                    < Link  to="/Sdetails_3"onClick={() => {window.location.href="/Sdetails_3"}}>  <span className="menu-text">BPM</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Investment Advisory Services</span></Link>
                                          </li>
                                            <li>
                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules ERP</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Subscription_plan"  onClick={() => {window.location.href="/Subscription_plan"}}> <span className="menu-text">Subscription ERP </span></Link>
                                          </li>
                                         
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">About</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Features</a></li>
                                    <li><a href="#">Our Partners</a></li>
                                    <li><a href="#">Affiliate Program</a></li>
                                          <li><a href="#">Pricing</a></li>
                                          
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Contact</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Support Ticket</a></li>
                                    <li><a href="#">Help Desk</a></li>
                                    <li><a href="#">Live Chat</a></li>
                                    <li><a href="#">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
        
                </div>
                
                <div className="row">
                    <div className="col">
                        <p className="copyright">Copyright &#169; 2024 BVCPartners LP. All Rights Reserved  </p>
                    </div>
                </div>
               
        
            </div>
        </div>


        
        <a href="#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up"></i>
            <i className="arrow-bottom fal fa-long-arrow-up"></i>
        </a>
    
    </div>

    <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <a href="index.html"><img src="assets/images/logo/light-logo.png" alt=""></img></a>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle">
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <nav className="site-mobile-menu">
                    <ul>
                                              <li>
                                                  < Link  to="/" onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  className="active" onClick={() => window.location.reload()}>   <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                              </li>
                                  <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>

                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                              </li>
                              <li>
                                                    < Link  to="/Login" className="active" onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                               
                                    </ul>
                          
                </nav>
            </div>
        </div>
    </div>
   
      
      </>


  );
}


export default About;
