
import { useEffect } from "react";
import lightlogo from "../assets/images/logo/light-logo.png";    
import { Link } from "react-router-dom";






function Service() {

 
const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
     // externalScript.onerror = loadError;
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = '../assets/js/main.js';
};
LoadExternalScript();


// useEffect(()=>{require('../assets/js/main.js')},[])

  return (

    
        <>
      
              <div id="page" className="section">
        
        <div className="header-section header-transparent sticky-header section">
        <div className="header-inner">
            <div className="container position-relative">
                <div className="row justify-content-between align-items-center">

                   
                   <div className="col-xl-2 col-auto order-0">
                        <div className="header-logo">
                                     
                                            < Link  to="/"  onClick={() => {window.location.href="/"}}>
                                 {/* <img src={darklogo} alt="dark-logo's logo"/> */}
                                    {/* <img src={iicon} alt="Anon's logo" width="120" height="36" /> */}
                                     <img src={lightlogo} alt="dark-logo's logo"/>
                                   </Link>
                                </div>
                            </div>
                        <div
                            className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                            <div className="menu-column-area d-none d-xl-block position-static">
                                <nav className="site-main-menu">
                                     <ul>
                                              <li>
                                                  < Link  to="/"  onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management"className="active" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                        </li>
                                              <li>
                                                    < Link  to="/Service" onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                        </li>
                                    </ul>
                                   
                                </nav>
                            </div>
                           
                            <div className="header-search-area ml-xl-7 ml-0">

                               
                               <div className="header-search">
                                    <a href="javascript:void(0)" className="header-search-toggle"></a>
                                </div>
                                
                            </div>
                           
                                  <div className="header-mobile-menu-toggle
                            onClick={toggleMobileMenu}
                            d-xl-none ml-sm-2">
                                <button className="toggle">
                                    <i className="icon-top"></i>
                                    <i className="icon-middle"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                           
                        </div>
                        

                       
                     
                    </div>
                </div>
            </div>
        </div>

         <div className="page-title-section section section-padding-top" data-overlay="0.7" data-bg-image="assets/images/bg/breadcrumb-bg-two.jpg">
            <div className="page-title">
                <div className="container">
                    <h1 className="title">Management Team</h1>
                </div>
            </div>
            
              </div>
              
    
       <div className="section section-padding">
            <div className="container">
                <div className="row pt--100 pb--80">

                   
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="work-left work-details" data-aos="fade-up">
                            <div className="portfolio-main-info">
                                {/* <h2 className="title">About the  project</h2> */}
                                <div className="thumbnail">
                                  {/* < Link  className="image" to="/Pdetails_1"  onClick={() => {window.location.href="/Pdetails_1"}}>  */}
                                   < Link  className="image" to="/Pdetails_2"  onClick={() => {window.location.href="/Pdetails_2"}}> 
                                          <img src="assets/images/blog/370/blog-2.jpg" alt="work" width="400" height="400"></img>
                                            </Link>
                            </div>
                               
                            
                                <div className="work-share">
                                          <h6 className="mt-6" >Olufemi Babatunde  Vaughan (MSc, PRINCE2, CISA, ITIL, FCIB, FICEN, IADT)  </h6>
                               
                                          <h6 >    olufemi.vaughan@bvcpartners.com.ng</h6>
                                          <h4 >Director of Tax and Business Process Management </h4>
                                          
                                      </div>
                                      {/* <div className="content mb-5" data-aos="fade-up">
                                            <p>Olufemi Babatunde Vaughan holds a Bachelor’s Degree (Hons.) in Economics from Lagos State University and a Master of Science (MSc) degree in Information System Management from the University of Roehampton London. He is a Certified Information System Auditor, PRINCE2 Practitioner, ITIL, Fellow of Chartered Institute of Bankers, Fellow of Chartered Institute of Economist, and an HABC level 3 International Award in Delivering Training. .</p>
                                        </div> */}
                                      
                            </div>
                        </div>
                    </div>

                   
                    <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
                              <div className="work-left work-details mt-6">
                                  <div className="content mb-5" data-aos="fade-up">
                                            <p>Olufemi Babatunde Vaughan holds a Bachelor’s Degree (Hons.) in Economics from Lagos State University and a Master of Science (MSc) degree in Information System Management from the University of Roehampton London. He is a Certified Information System Auditor, PRINCE2 Practitioner, ITIL, Fellow of Chartered Institute of Bankers, Fellow of Chartered Institute of Economist, and an HABC level 3 International Award in Delivering Training. .</p>
                                        </div>
                            <div className="work-main-info">
                                <div className="work-content">
                                    {/* <h6 className="title" data-aos="fade-up">Director of Tax and Business Process Management </h6> */}

                                    <div className="desc mt-8">
                                        <div className="content mb-5" data-aos="fade-up">
                                            <p>He was a Director and Managing Partner at Tungsten Information System and Consultancy Ltd and a Senior Information Systems Security Management Consultant; he facilitates training on Information System Audit Certification (CISA), Project Management, Process Optimization, IT Securities, IT Service Management, Innovative Technology, Strategic Information System Planning, Business Process Management, and Cloud computing.</p>
                                        </div>
                                        <div className="content mb-5" data-aos="fade-up">
                                            <p>He has worked as a Consultant/Trainer at Distant Education for Africa (DeAfrica) in Kenya for the award of International Cyber Security Driver’s License (ICSDL) and a Regional Information Technology Infrastructure Manager at Ecobank Nigeria Plc </p>
                                        </div>
                                        <div className="content mb-12" data-aos="fade-up">
                                            <p>He has published books on Strategic Information System Planning, Innovative Technology Roadmap, and Optimizing BPM with Cloud Computing Technology in Developing Economy.</p>
                                              </div>
                                               <div className="digital-marketing" data-aos="fade-up">
                           <div className="inner">
                                <p>He has over 20 years working experience in organization building and development, with emphasis on key areas such as Innovative Tax Management, Project Management, Capacity Building, Impact Evaluation, Learning and Knowledge, Innovation, Public-private Partnership, Digital/Mobile Technology Management and Cross-disciplinary/Multi-disciplinary Approaches.</p>
                            </div>
                        </div>

                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                
                
                
               
                


            </div>
        </div>
 <div className="footer-section section" data-bg-color="#030e22">
            <div className="container">
        
                
                <div className="row mb-lg-14 mb-md-10 mb-6">
        
                    
                          
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                        
                            <div className="footer-widget-content">
                                <div className="content">
        
        
                                    <p><a href="#">+2348117390319</a></p>
                                    <p><a href="#">info@bvcpartners.com.ng</a> </p>
                                </div>
                                <div className="footer-social-inline">
                                    
 <a href="https://www.facebook.com/bvcpartners.com.ng?mibextid=ZbWKwL/"><i className="fab fa-facebook-square"></i></a>
                                         <a href=" https://www.instagram.com/bvcpartners?utm_source=qr&igsh=YnU0aHJveWxnbXhr/"><i className="fab fa-instagram"></i></a>

                                   <a href="https://www.linkedin.com/company/bola-vaughan-co-chartered-accountants/"><i className="fab fa-linkedin-in"></i></a>
                                    
        
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Services</h4>
                            <div className="footer-widget-content">
                                <ul>
                                          <li>
                                    < Link  to="/Sdetails_2"onClick={() => {window.location.href="/Sdetails_2"}}>  <span className="menu-text">Tax Mgt Services</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Audit Services</span></Link>
                                          </li>
                                               <li>
                                    < Link  to="/Sdetails_6"onClick={() => {window.location.href="/Sdetails_6"}}>  <span className="menu-text">Human Capital Mgt & Development</span></Link>         
                                          </li>
                                          <li>
                                    < Link  to="/Sdetails_5"onClick={() => {window.location.href="/Sdetails_5"}}>  <span className="menu-text">Cyber Security & IT Services</span></Link>         
                                   </li>
                                          
                                   
                                           <li>
                                    < Link  to="/Sdetails_3"onClick={() => {window.location.href="/Sdetails_3"}}>  <span className="menu-text">BPM</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Investment Advisory Services</span></Link>
                                          </li>
                                            <li>
                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules ERP</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Subscription_plan"  onClick={() => {window.location.href="/Subscription_plan"}}> <span className="menu-text">Subscription ERP </span></Link>
                                          </li>
                                         
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">About</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Features</a></li>
                                    <li><a href="#">Our Partners</a></li>
                                    <li><a href="#">Affiliate Program</a></li>
                                          <li><a href="#">Pricing</a></li>
                                          
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Contact</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Support Ticket</a></li>
                                    <li><a href="#">Help Desk</a></li>
                                    <li><a href="#">Live Chat</a></li>
                                    <li><a href="#">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
        
                </div>
                
                <div className="row">
                    <div className="col">
                        <p className="copyright">Copyright &#169; 2024 BVCPartners LP. All Rights Reserved  </p>
                    </div>
                </div>
               
        
            </div>
        </div>


  
        <a href="#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up"></i>
            <i className="arrow-bottom fal fa-long-arrow-up"></i>
        </a>
  
    </div>

    <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <a href="index.html"><img src="assets/images/logo/light-logo.png" alt=""></img></a>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle">
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <nav className="site-mobile-menu">
                <ul>
                                              <li>
                                                  < Link  to="/"  onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" className="active"onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                        </li>
                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                        </li>
                                    </ul>
                </nav>
            </div>
              </div>
              
     
    </div>
      
      </> 

    
  );
}


export default Service;