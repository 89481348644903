
import React, { useState, useEffect } from 'react';
import '../assets/js/main.js'
//import React, { createContext, ReactNode, useContext, useState } from 'react';

import logo from './logo.svg';
//import { Suspense, lazy, useEffect } from "react";

//import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

//import darklogo from "./assets/images/logo/light-logo.png";
import lightlogo from "../assets/images/logo/light-logo.png";    

import shapeanimation from "../assets/images/shape-animation/video-shape-1.png";
import skillvideo from "../assets/images/video/skill-video.jpg";
import homeoneabout1 from "../assets/images/about/home-one-about/home_agency_about_1.jpg";
import homeoneabout2 from "../assets/images/about/home-one-about/home_agency_about_2.jpg";
import shapeanimation2 from "../assets/images/shape-animation/about-shape-1.png";
import about5 from "../assets/images/about/about-5.jpg";
import about6 from "../assets/images/about/about-6.jpg";
import blog1 from "../assets/images/blog/370/blog-1.jpg";
import blog2 from "../assets/images/blog/370/blog-2.jpg";
import blog3 from "../assets/images/blog/370/blog-3.jpg";
import lineabasic from "../assets/images/svg/linea/linea-basic-map.svg";
import lineabasicmessage from "../assets/images/svg/linea/linea-basic-message-txt.svg";
import lineabasicmail from "../assets/images/svg/linea/linea-basic-mail-open-text.svg";
import contactshape from "../assets/images/shape-animation/contact-shape.png";                       


//import arrowupright from "./assets/images/icons/arrow-up-right.svg";
import type { FC } from "react";
import { Link } from 'react-router-dom';


function Home() {

 
   // useExternalScript('public/assets/js/main2.js');
    
const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
     // externalScript.onerror = loadError;
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = '../assets/js/main.js';
};
    LoadExternalScript();












  return (





<>
    
    

    <div id="page" className="section">
        

    <div className="header-section header-transparent sticky-header section">
        <div className="header-inner">
            <div className="container position-relative">
                <div className="row justify-content-between align-items-center">

                   
                    <div className="col-xl-2 col-auto order-0">
                        <div className="header-logo">
                                     
                                           < Link  to="/"onClick={() => {window.location.href="/"}}>
                                 {/* <img src={darklogo} alt="dark-logo's logo"/> */}
                                    {/* <img src={iicon} alt="Anon's logo" width="120" height="36" /> */}
                                     <img src={lightlogo} alt="dark-logo's logo"/>
                                   </Link>
                                </div>
                            </div>
                      
                        <div
                            className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                            <div className="menu-column-area d-none d-xl-block position-static">
                                <nav className="site-main-menu">
                                  <ul>
                                              <li>
                                                  < Link  to="/"  onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" className="active" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                              </li>
                                                  <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>

                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                              </li>
                                              <li>
                                                    < Link  to="/Login"  onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                           
                            <div className="header-search-area ml-xl-7 ml-0">

                                <div className="header-search">
                                    <a href="javascript:void(0)" className="header-search-toggle"></a>
                                </div>
                                
                            </div>
                           
                                  <div className="header-mobile-menu-toggle
                            onClick={toggleMobileMenu}
                            d-xl-none ml-sm-2">
                                <button className="toggle">
                                    <i className="icon-top"></i>
                                    <i className="icon-middle"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                           
                        </div>
                        

                    

                    </div>
                </div>
            </div>
        </div>
       
        <div className="main-search-active">
            <div className="sidebar-search-icon">
                <button className="search-close"><i className="pe-7s-close"></i></button>
            </div>
            <div className="sidebar-search-input">
                <form action="#">
                    <div className="form-search">
                        <input id="search" className="input-text" value="" placeholder="" type="search"></input>
                            <button>
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                </form>
                <p className="form-description">Hit enter to search or ESC to close</p>
            </div>
        </div>
       
         <div className="page-title-section section section-padding-top" data-overlay="0.7" data-bg-image="assets/images/bg/breadcrumb-bg-two.jpg">
            
                  <div className="page-title">
                <div className="container">
                    <h1 className="title">Management Team</h1>
                </div>
            </div>
            
        </div>


        
   
   
              <div className="section section-padding-t90-b100">
                  
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8">
                       
                        <div className="section-title-two mb-lg-12 mb-md-6" data-aos="fade-up">
                            <span className="sub-title">Our Management Team</span>
                            <h3 className="title">The most interesting facts about our management team,
                                is the uniqueness of individualities</h3>
                        </div>
                       
                    </div>
                   
                </div>

                <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">

                       
      
                        <div className="col mb-6" data-aos="fade-up">
                    
                        <div className="blog">
                            <div className="thumbnail">
                                  {/* < Link  className="image" to="/Pdetails_1"  onClick={() => {window.location.href="/Pdetails_1"}}>  */}
                                   < Link  className="image" to="/Pdetails_1"  onClick={() => {window.location.href="/Pdetails_1"}}> 
                                          <img src="assets/images/blog/370/blog-1.jpg" alt="work" width="350" height="400"></img>
                                            </Link>
                            </div>

                            
                                  <div className="info">
                                      {/* <h3 className="title"> Bolanle Vaughan (FCA, MBA)</h3> */}
      
                                     
    <div className="d-flex align-items-center ">
                                          <h4> Bolanle Vaughan</h4> <span><b>(FCA, MBA)</b></span>
                                           <h4> Bolanle Vaughan</h4> <h6><b>(FCA, MBA)</b></h6>
    </div>
  

                                      {/* <h5> Bolanle Vaughan</h5><h6> (FCA, MBA)</h6> */}
                                      
                                      <h3 className="title">< Link   to="/Pdetails_1"   onClick={() => {window.location.href="/Pdetails_1"}}><b>Director of Audit and Investment Advisory</b>  </Link>
                                          </h3>
                                      
                                      <p className="desc">
                                         Bolanle is a young lady with proven skills and expertise, a Chartered Accountant (ACA) by profession from the Institute of Chartered Accountant of Nigeria (ICAN). She holds a Bachelor of Science (BSc.) ...</p>
                                      
                                          < Link className="link "  to="/Pdetails_1"   onClick={() => {window.location.href="/Pdetails_1"}}><mark>Read More</mark></Link>
                                         
                            </div>
                        </div>
                       
                    </div>
                             

                     <div className="col mb-6" data-aos="fade-up">
                    
                        <div className="blog">
                            <div className="thumbnail">
                                 < Link  className="image" to="/Pdetails_4"  onClick={() => {window.location.href="/Pdetails_4"}}> 
                                          <img src="assets/images/blog/370/blog-4.jpg" alt="work" width="350" height="400"></img>
                                            </Link>
                            </div>
                                  <div className="info">
                                     <div className="d-flex align-items-center ">
                                          <h4>Ladapo Babatunde Sunday</h4>
                                          <span><b>(ACIPM, ANISP, ANITAD)</b></span>
    </div>
  
                                       <h3 className="title">< Link   to="/Pdetails_4"   onClick={() => {window.location.href="/Pdetails_4"}}><b>Director of Training and Human Capital Management</b>  </Link>
                                          </h3>
                                
                                      <p className="desc">
                                       He brings a diverse background to his role as Training Director at BVCPartners LP, blending academic qualifications with professional certifications and experiences
                                          ...</p>
                               < Link className="link "  to="/Pdetails_4"   onClick={() => {window.location.href="/Pdetails_4"}}><mark>Read More</mark></Link>
                                         
                            </div>
                        </div>
                       
                    </div>
                          
                    
                    
                     <div className="col mb-6" data-aos="fade-up">
                    
                        <div className="blog">
                            <div className="thumbnail">
                                          < Link  className="image" to="/Pdetails_3"  onClick={() => {window.location.href="/Pdetails_3"}}> 
                                          <img src="assets/images/blog/370/blog-3.jpg" alt="work" width="350" height="400"></img>
                                            </Link>
                            </div>
                                  <div className="info">
                                      {/* <h3 className="title"> Olu Olateju(CISM, TOGAF, MCSE, CAA, SAP) </h3> */}
                                     < div className="d-flex align-items-center ">
    <h4> Olu Olateju</h4> <span><b>(CISM, TOGAF, MCSE, CAA, SAP)</b></span>
    </div>
                                       <h3 className="title">< Link   to="/Pdetails_3"   onClick={() => {window.location.href="/Pdetails_3"}}><b>Director of Cybersecurity and Information Technology Services</b>  </Link>
                                          </h3>
                                
                                      <p className="desc">Concise design and delivery of secure cloud/on-premise system solutions, re-engineering and requirement solutions, optimized...</p>
                           < Link className="link "  to="/Pdetails_3"   onClick={() => {window.location.href="/Pdetails_3"}}><mark>Read More</mark></Link>
                                         
                            </div>
                        </div>
                       
                    </div>
                     <div className="col mb-6" data-aos="fade-up">
                    
                        <div className="blog">
                            <div className="thumbnail">
                                            < Link  className="image" to="/Pdetails_2"  onClick={() => {window.location.href="/Pdetails_2"}}> 
                                          <img src="assets/images/blog/370/blog-2.jpg" alt="work" width="350" height="400"></img>
                                            </Link>
                            </div>
                                  <div className="info">
                                      {/* <h3 className="title"> Olufemi Babatunde  Vaughan (MSc, PRINCE2, CISA, ITIL, FCIB, FICEN, IADT) </h3> */}
                                      < div className="d-flex align-items-center ">
                                          {/* <h4> Olufemi Babatunde  Vaughan</h4> <span><b>(MSc, PRINCE2, CISA, ITIL, FCIB, FICEN, IADT)</b></span> */}
                                              <h4> Olufemi Babatunde  Vaughan</h4> <span><b>(MSc, PRINCE2, CISA, ITIL, FCIB, FICEN, IADT)</b></span>
    </div>
                                       <h3 className="title">< Link   to="/Pdetails_2"   onClick={() => {window.location.href="/Pdetails_2"}}><b>Director of Tax and Business Process Management</b>  </Link>
                                          </h3>
                            
                                      <p className="desc">Olufemi Babatunde Vaughan holds Bachelor’s Degree (Hons.) in Economics from Lagos State University and a Master of Science (MSc)...</p>
                                 
                                       < Link className="link "  to="/Pdetails_2"   onClick={() => {window.location.href="/Pdetails_2"}}><mark>Read More</mark></Link>
                               
                            </div>
                        </div>
                       
                    </div>

                    


                
                </div>
            </div>
        </div>
       


    
   

         <div className="footer-section section" data-bg-color="#030e22">
            <div className="container">
        
                
                <div className="row mb-lg-14 mb-md-10 mb-6">
        
                    
                          
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                        
                            <div className="footer-widget-content">
                                <div className="content">
        
        
                                    <p><a href="#">+2348117390319</a></p>
                                    <p><a href="#">info@bvcpartners.com.ng</a> </p>
                                </div>
                                <div className="footer-social-inline">
                                    
 <a href="https://www.facebook.com/bvcpartners.com.ng?mibextid=ZbWKwL/"><i className="fab fa-facebook-square"></i></a>
                                         <a href=" https://www.instagram.com/bvcpartners?utm_source=qr&igsh=YnU0aHJveWxnbXhr/"><i className="fab fa-instagram"></i></a>

                                   <a href="https://www.linkedin.com/company/bola-vaughan-co-chartered-accountants/"><i className="fab fa-linkedin-in"></i></a>
                                    
        
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Services</h4>
                            <div className="footer-widget-content">
                                <ul>
                                          <li>
                                    < Link  to="/Sdetails_2"onClick={() => {window.location.href="/Sdetails_2"}}>  <span className="menu-text">Tax Mgt Services</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Audit Services</span></Link>
                                          </li>
                                               <li>
                                    < Link  to="/Sdetails_6"onClick={() => {window.location.href="/Sdetails_6"}}>  <span className="menu-text">Human Capital Mgt & Development</span></Link>         
                                          </li>
                                          <li>
                                    < Link  to="/Sdetails_5"onClick={() => {window.location.href="/Sdetails_5"}}>  <span className="menu-text">Cyber Security & IT Services</span></Link>         
                                   </li>
                                          
                                   
                                           <li>
                                    < Link  to="/Sdetails_3"onClick={() => {window.location.href="/Sdetails_3"}}>  <span className="menu-text">BPM</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Investment Advisory Services</span></Link>
                                          </li>
                                            <li>
                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules ERP</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Subscription_plan"  onClick={() => {window.location.href="/Subscription_plan"}}> <span className="menu-text">Subscription ERP </span></Link>
                                          </li>
                                         
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">About</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Features</a></li>
                                    <li><a href="#">Our Partners</a></li>
                                    <li><a href="#">Affiliate Program</a></li>
                                          <li><a href="#">Pricing</a></li>
                                          
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Contact</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Support Ticket</a></li>
                                    <li><a href="#">Help Desk</a></li>
                                    <li><a href="#">Live Chat</a></li>
                                    <li><a href="#">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
        
                </div>
                
                <div className="row">
                    <div className="col">
                        <p className="copyright">Copyright &#169; 2024 BVCPartners LP. All Rights Reserved  </p>
                    </div>
                </div>
               
        
            </div>
        </div>










              

    <a href="#" className="scroll-top" id="scroll-top">
        <i className="arrow-top fal fa-long-arrow-up"></i>
        <i className="arrow-bottom fal fa-long-arrow-up"></i>
    </a>

          </div>
          
          <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <a href="index.html"><img src={lightlogo} alt=""></img></a>
                </div>
                      <div className="mobile-menu-close
                       onClick={toggleMobileMenu} ">
                    <button className="toggle">
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <nav className="site-mobile-menu">
                     <ul>
                                              <li>
                                                  < Link  to="/" className="active" onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                              </li>
                                  <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>

                                              <li>
                                                    < Link  to="/Service"  onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                        </li>
                                        <li>
                                                    < Link  to="/Login" className="active" onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                              </li>
                              
                                    </ul>
                </nav>
            </div>
        </div>


              
          </div>
      </>


    
  );
}

export default Home;
