
import { useEffect } from "react";
import lightlogo from "../assets/images/logo/light-logo.png";    
import { Link } from "react-router-dom";






function Service() {

 
const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
     // externalScript.onerror = loadError;
      externalScript.id = "external";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = '../assets/js/main.js';
};
LoadExternalScript();


// useEffect(()=>{require('../assets/js/main.js')},[])

  return (

    
        <>
      
              <div id="page" className="section">
        
        <div className="header-section header-transparent sticky-header section">
        <div className="header-inner">
            <div className="container position-relative">
                <div className="row justify-content-between align-items-center">

                   
                   <div className="col-xl-2 col-auto order-0">
                        <div className="header-logo">
                                     
                                            < Link  to="/"  onClick={() => {window.location.href="/"}}>
                                 {/* <img src={darklogo} alt="dark-logo's logo"/> */}
                                    {/* <img src={iicon} alt="Anon's logo" width="120" height="36" /> */}
                                     <img src={lightlogo} alt="dark-logo's logo"/>
                                   </Link>
                                </div>
                            </div>
                        <div
                            className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                            <div className="menu-column-area d-none d-xl-block position-static">
                                <nav className="site-main-menu">
                                     <ul>
                                              <li>
                                                  < Link  to="/"  onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                                              </li>
                                                  <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>

                                              <li>
                                                    < Link  to="/Service" className="active" onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                                              </li>
                                                     <li>
                                                    < Link  to="/Login"  onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                    </ul>
                                   
                                </nav>
                            </div>
                           
                            <div className="header-search-area ml-xl-7 ml-0">

                               
                               <div className="header-search">
                                    <a href="javascript:void(0)" className="header-search-toggle"></a>
                                </div>
                                
                            </div>
                           
                                  <div className="header-mobile-menu-toggle
                            onClick={toggleMobileMenu}
                            d-xl-none ml-sm-2">
                                <button className="toggle">
                                    <i className="icon-top"></i>
                                    <i className="icon-middle"></i>
                                    <i className="icon-bottom"></i>
                                </button>
                            </div>
                           
                        </div>
                        

                       
                     
                    </div>
                </div>
            </div>
        </div>

       
    
        <div className="page-title-section section section-padding-top" data-overlay="0.7" data-bg-image="assets/images/bg/breadcrumb-bg-two.jpg">
            <div className="page-title">
                <div className="container">
                    <h1 className="title">We work with bold brands that we believe in</h1>
                </div>
            </div>
            
        </div>
       
        <div className="section section-padding-t90-b100">
            <div className="container">

               
                <div className="section-title text-center" data-aos="fade-up">
                    <h2 className="title fz-32">Our Key Services Are Enlisted Below.</h2>
                </div>
               

               
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">

                 
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="work">
                            <div className="thumbnail">
                                    
                                         
                                   < Link  className="image" to="/Sdetails_2"  onClick={() => {window.location.href="/Sdetails_2"}}> 
                                          <img src="assets/images/project/hero-2.jpg" alt="work" width="370" height="150"></img>
                                            </Link>
                                         
                            
                            </div>
                            <div className="info">
                                <h3 className="title">< Link   to="/Sdetails_2"  onClick={() => {window.location.href="/Sdetails_2"}}><b>Tax Management Services</b>  </Link></h3>
                               (a) Tax Planning<br></br>
                                (b) Tax Policy<br></br>
                                (c) Personal Income Taxes
                                
                            </div>
                        </div>
                    </div>
                
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="work">
                            <div className="thumbnail">
                            < Link  className="image" to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> 
                                          <img src="assets/images/project/hero-1.jpg" alt="work" width="370" height="150"></img>
                                            </Link>
                                         
                            </div>
                            <div className="info">
                                   
                                      <h3 className="title">< Link   to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}><b>Audit Services</b>  </Link></h3>
                                (a) Assurance Services<br></br>
                                    (b) Consulting Services<br></br>
                                    (c) Special Investigations
                            </div>
                        </div>
                    </div>
                    
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="work">
                            <div className="thumbnail">
                            < Link  className="image" to="/Sdetails_5"  onClick={() => {window.location.href="/Sdetails_5"}}> 
                                          <img src="assets/images/project/hero-5.jpg" alt="work" width="370" height="150"></img>
                                            </Link>
                                         
                            </div>
                            <div className="info">
                            
       <h3 className="title">< Link   to="/Sdetails_5"  onClick={() => {window.location.href="/Sdetails_5"}}><b>Cyber Security & IT Services</b>  </Link></h3>                       
                               (a) Information and Data Security<br></br>
                                    (b) Software as a service<br></br>
                                    (c) Cloud computing
                    
                            </div>
                        </div>
                    </div>
          
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="work">
                            <div className="thumbnail">
                               < Link  className="image" to="/Sdetails_3"  onClick={() => {window.location.href="/Sdetails_3"}}> 
                                          <img src="assets/images/project/hero-3.jpg" alt="work" width="370" height="150"></img>
                                            </Link>
                            </div>
                            <div className="info">
                                
                                             <h3 className="title">< Link   to="/Sdetails_3"  onClick={() => {window.location.href="/Sdetails_3"}}><b>Business Process Management & Re-engineering Services</b>  </Link></h3>
                                

                              
                               
                            </div>
                        </div>
                    </div>
                    
                  
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="work">
                            <div className="thumbnail">
                        < Link  className="image" to="/Sdetails_4"  onClick={() => {window.location.href="/Sdetails_4"}}> 
                                          <img src="assets/images/project/hero-4.jpg" alt="work" width="370" height="150"></img>
                                            </Link>
                            
                            </div>
                            <div className="info">
                                 <h3 className="title">< Link   to="/Sdetails_4"  onClick={() => {window.location.href="/Sdetails_4"}}><b>Investment Advisory Services</b>  </Link></h3>  
                                (a) Our Investment Advisory Services<br></br>
                                      (b) Benefits of Our Investment Advisory Services<br></br>
                                      (c) Choosing the Right Investment Advisor
                                

                            </div>
                        </div>
                    </div>
                    
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="work">
                            <div className="thumbnail">
                                   < Link  className="image" to="/Sdetails_6"  onClick={() => {window.location.href="/Sdetails_6"}}> 
                                          <img src="assets/images/project/hero-6.jpg" alt="work" width="370" height="150"></img>
                                            </Link>
                            
                            </div>
                            <div className="info">
                                  
                                         <h3 className="title">< Link   to="/Sdetails_6"  onClick={() => {window.location.href="/Sdetails_6"}}><b>Human Capital Mag & Development</b>  </Link></h3>  
                       (a)  Performance Management<br></br>
                                (b) Com​pany Culture<br></br>
                                (c) Employe  Relations 
                            </div>
                        </div>
                    </div>
{/* <div className="col mb-6" data-aos="fade-up">
                        <div className="work">
                            <div className="thumbnail">
                               < Link  className="image" to="/Sdetails_7"  onClick={() => {window.location.href="/Sdetails_7"}}> 
                                          <img src="assets/images/project/hero-7.jpg" alt="work" width="370" height="150"></img>
                                            </Link>
                            
                            </div>
                            <div className="info">
                                
         <h3 className="title">< Link   to="/Sdetails_7"  onClick={() => {window.location.href="/Sdetails_7"}}><b>Chartered Accountants</b>  </Link></h3>                 
                            </div>
                        </div>
                    </div> */}

                          
                    

                </div>

                      
               

            </div>
        </div>
 
        <div className="section section-padding-t110-b120 newsletter-section" data-overlay="0.7" data-bg-image="assets/images/bg/cta.jpg">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                       
                        <div className="cta-content text-center">
                           
                            <div className="section-title color-light text-center mb-0" data-aos="fade-up">
                                <h2 className="title fz-32">Want to start a project with us? It's so easy</h2>
                                <p className="sub-title fz-18">Whether you want to work with us or are interested in learning
                                   <br></br> more about what we do, we’d love to hear from you.</p>
                            </div>
                        
                            <a href="#" className="btn btn-primary btn-hover-secondary mt-6">Start Project</a>
                        </div>
                        
                    </div>
                </div>
            </div>

        
            <div className="shape shape-1 scene">
                <span data-depth="1">
                    <img src="assets/images/shape-animation/newsletter-shape.png" alt=""></img>
                </span>
            </div>
        
        </div>
    

    
        <div className="section section-padding-top section-padding-bottom-190">
            <div className="container">

             
                <div className="row">

                    
                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image js-tilt">
                                <img src="assets/images/about/about-8.jpg" alt=""></img>
                            </div>
                            <div className="about-image js-tilt">
                                <img src="assets/images/about/about-7.jpg"  alt=""></img>
                            </div>
                        
                            <div className="shape shape-1 scene">
                                <span data-depth="4"><img src="assets/images/shape-animation/about-shape-1.png" alt=""></img></span>
                            </div>
                        
                        </div>
                    </div>
                   
                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up">
                       
                        <div className="about-content-area">
                        
                            <div className="section-title-two">
                                <span className="sub-title">Together we'll discover your brand's soul</span>
                                <h3 className="title">We practice an agnostic approach to branding</h3>
                            </div>
                        

                            <p>We guide and nurture companies to be part of a very human world by creating brands that speak to the intangible and connect emotionally.</p>

                            <a className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" href="#">Learn More</a>
                        </div>
                    
                    </div>
                 

                </div>
            

            </div>
        </div>
     
        <div className="faq-section section section-padding-top" data-bg-color="#f8faff">
            <div className="container">
                <div className="row row-cols-lg-2 row-cols-1 mb-n6">
                  
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="faq-content">
                        
                            <div className="section-title-two">
                                <span className="sub-title">Frequently asked questions</span>
                                <h3 className="title">You’ve got questions and <br></br> we’ve got answers</h3>
                            </div>
                            

                            <div className="agency-accordion max-mb-n30" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <a href="#" className="acc-btn border-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What exactly is branding?
                                            </a>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">Branding is more than a logo and a clever tagline. Your brand tells the story of your company. It’s your message and your values. When that happens, remarkable things occur.
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h5 className="mb-0">
                                            <a href="#" className="acc-btn border-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What’s the branding process like?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body">Branding is more than a logo and a clever tagline. Your brand tells the story of your company. It’s your message and your values. When that happens, remarkable things occur.
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h5 className="mb-0">
                                            <a href="#" className="acc-btn border-0 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What type of creative services do you offer?
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">Branding is more than a logo and a clever tagline. Your brand tells the story of your company. It’s your message and your values. When that happens, remarkable things occur.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                   

                    <div className="col mb-6 pl-xl-12" data-aos="fade-up">
                     
                        <div className="about-image-area faq-image-area">
                            <div className="about-image right-n50 js-tilt">
                                <img src="assets/images/faq/faq-2.jpg" alt=""></img>
                            </div>
                            <div className="about-image js-tilt">
                                <img src="assets/images/faq/faq-1.jpg" alt=""></img>
                            </div>
                           
                            <div className="shape shape-1 scene">
                                <span data-depth="4"><img src="assets/images/shape-animation/video-shape-1.png" alt=""></img></span>
                            </div>
                           
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
      
 <div className="footer-section section" data-bg-color="#030e22">
            <div className="container">
        
                
                <div className="row mb-lg-14 mb-md-10 mb-6">
        
                    
                          
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                        
                            <div className="footer-widget-content">
                                <div className="content">
        
        
                                    <p><a href="#">+2348117390319</a></p>
                                    <p><a href="#">info@bvcpartners.com.ng</a> </p>
                                </div>
                                <div className="footer-social-inline">
                                    
 <a href="https://www.facebook.com/bvcpartners.com.ng?mibextid=ZbWKwL/"><i className="fab fa-facebook-square"></i></a>
                                         <a href=" https://www.instagram.com/bvcpartners?utm_source=qr&igsh=YnU0aHJveWxnbXhr/"><i className="fab fa-instagram"></i></a>

                                   <a href="https://www.linkedin.com/company/bola-vaughan-co-chartered-accountants/"><i className="fab fa-linkedin-in"></i></a>
                                    
        
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Services</h4>
                            <div className="footer-widget-content">
                                <ul>
                                          <li>
                                    < Link  to="/Sdetails_2"onClick={() => {window.location.href="/Sdetails_2"}}>  <span className="menu-text">Tax Mgt Services</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Audit Services</span></Link>
                                          </li>
                                               <li>
                                    < Link  to="/Sdetails_6"onClick={() => {window.location.href="/Sdetails_6"}}>  <span className="menu-text">Human Capital Mgt & Development</span></Link>         
                                          </li>
                                          <li>
                                    < Link  to="/Sdetails_5"onClick={() => {window.location.href="/Sdetails_5"}}>  <span className="menu-text">Cyber Security & IT Services</span></Link>         
                                   </li>
                                          
                                   
                                           <li>
                                    < Link  to="/Sdetails_3"onClick={() => {window.location.href="/Sdetails_3"}}>  <span className="menu-text">BPM</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Sdetails_1"  onClick={() => {window.location.href="/Sdetails_1"}}> <span className="menu-text">Investment Advisory Services</span></Link>
                                          </li>
                                            <li>
                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules ERP</span></Link>         
                                   &#160; & &#160;
                                    < Link  to="/Subscription_plan"  onClick={() => {window.location.href="/Subscription_plan"}}> <span className="menu-text">Subscription ERP </span></Link>
                                          </li>
                                         
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">About</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Features</a></li>
                                    <li><a href="#">Our Partners</a></li>
                                    <li><a href="#">Affiliate Program</a></li>
                                          <li><a href="#">Pricing</a></li>
                                          
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 mb-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Contact</h4>
                            <div className="footer-widget-content">
                                <ul>
                                    <li><a href="#">Support Ticket</a></li>
                                    <li><a href="#">Help Desk</a></li>
                                    <li><a href="#">Live Chat</a></li>
                                    <li><a href="#">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                
        
                </div>
                
                <div className="row">
                    <div className="col">
                        <p className="copyright">Copyright &#169; 2024 BVCPartners LP. All Rights Reserved  </p>
                    </div>
                </div>
               
        
            </div>
        </div>


  
        <a href="#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up"></i>
            <i className="arrow-bottom fal fa-long-arrow-up"></i>
        </a>
  
    </div>

    <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
            <div className="mobile-menu-header">
                <div className="mobile-menu-logo">
                    <a href="index.html"><img src="assets/images/logo/light-logo.png" alt=""></img></a>
                </div>
                <div className="mobile-menu-close">
                    <button className="toggle">
                        <i className="icon-top"></i>
                        <i className="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div className="mobile-menu-content">
                <nav className="site-mobile-menu">
                <ul>
                                              <li>
                                                  < Link  to="/"  onClick={() => {window.location.href="/"}}> <span className="menu-text">Home</span></Link>
                                                  
                                      
                                              </li>
                                            
                                              <li>
                                                    < Link   to="/About"  onClick={() => {window.location.href="/About"}}>  <span className="menu-text">About Us</span></Link>
                                         
                                        </li>
                                                <li>
                                                    < Link  to="/Management" onClick={() => {window.location.href="/Management"}}> <span className="menu-text">Our Team</span></Link>
                                          
                              </li>
                                  <li className="has-children">
                                            <a href="#"><span className="menu-text">ERP Solutions</span></a>
                                            <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                            <ul className="sub-menu">
                                                
                                               <li>
                                                    < Link  to="/Subscription_plan"onClick={() => {window.location.href="/Subscription_plan"}}>  <span className="menu-text">Subscription Plan</span></Link>
                                          
                                        </li>
                                                 <li>
                                                    < Link  to="/Modules"onClick={() => {window.location.href="/Modules"}}>  <span className="menu-text">Modules</span></Link>
                                          
                                        </li>     
                         
                                            </ul>
                                        </li>

                                              <li>
                                                    < Link  to="/Service" className="active" onClick={() => {window.location.href="/Service"}}> <span className="menu-text">Services</span></Link>
                         
                                        </li>
                                       
                                              <li>
                                                    < Link  to="/Career"onClick={() => {window.location.href="/Career"}}>  <span className="menu-text">Career</span></Link>
                                          
                                        </li>
                                        
                                        
                                              <li>
                                                    < Link  to="/Contact"  onClick={() => {window.location.href="/Contact"}}> <span className="menu-text">Contact Us</span></Link>
                                          
                              </li>
                                     <li>
                                                    < Link  to="/Login"  onClick={() => {window.location.href="/Login"}}> <span className="menu-text">Login</span></Link>
                                          
                                        </li>
                                    </ul>
                </nav>
            </div>
              </div>
              
     
    </div>
      
      </> 

    
  );
}


export default Service;