import useta, { useEffect } from 'react';
import useState from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//import React, { createContext, ReactNode, useContext, useState } from 'react';

import logo from './logo.svg';
//import { Suspense, lazy, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Home from "./Pages/home";
import About from "./Pages/About";
import Subscription_plan from "./Pages/Subscription_plan";
import Modules from "./Pages/Modules";
import Login from "./Pages/Login";
import Service from "./Pages/Service";
import Contact from "./Pages/Contact";
import Career from "./Pages/Career";
import Management from "./Pages/Management";
import Sdetails_1 from "./Pages/Sdetails_1";
import Pdetails_1 from "./Pages/Pdetails_1";
import Pdetails_2 from "./Pages/Pdetails_2";
import Pdetails_3 from "./Pages/Pdetails_3";
import Pdetails_4 from "./Pages/Pdetails_4";
import Sdetails_2 from "./Pages/Sdetails_2";
import Sdetails_3 from "./Pages/Sdetails_3";
import Sdetails_4 from "./Pages/Sdetails_4";
import Sdetails_5 from "./Pages/Sdetails_5";
import Sdetails_6 from "./Pages/Sdetails_6";
import Sdetails_7 from "./Pages/Sdetails_7";
function App() {




useEffect(()=>{require('./assets/js/main.js')},[])

  return (

    <div className="App">
       <ToastContainer position="top-right"/>
<Router >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
           <Route path="/login" element={<Login />} />
          <Route path="/service" element={<Service />} />
          <Route path="/Subscription_plan" element={<Subscription_plan />} />
           <Route path="/Modules" element={<Modules />} />
          <Route path="/Career" element={<Career />} />
           <Route path="/management" element={<Management />} />
          <Route path="/contact" element={<Contact />} />
           <Route path="/Sdetails_1" element={<Sdetails_1/>} />
           <Route path="/Sdetails_2" element={<Sdetails_2/>} />
          <Route path="/Sdetails_3" element={<Sdetails_3 />} />
          <Route path="/Sdetails_4" element={<Sdetails_4 />} />
          <Route path="/Sdetails_5" element={<Sdetails_5/>} />
          <Route path="/Sdetails_6" element={<Sdetails_6/>} />
          <Route path="/Sdetails_7" element={<Sdetails_7 />} />
          <Route path="/Pdetails_1" element={<Pdetails_1 />} />
          <Route path="/Pdetails_2" element={<Pdetails_2 />} />
          <Route path="/Pdetails_3" element={<Pdetails_3 />} />
           <Route path="/Pdetails_4" element={<Pdetails_4/>} />
          
          
        
        </Routes>
      </Router>

     
    </div>
    
  );
}

export default App;
